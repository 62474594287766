import styled, { css, keyframes } from "styled-components";
import { white, black, grey, beige } from "theme/colors";
import * as variables from "theme/variables";
import { responsive } from "theme";

const fadeInTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeInTopReverse = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const fadeInBottomReverse = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const fadeInBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(-100%);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const SocialsStyle = styled.div`
  bottom: 0;
  background: #fff;
  z-index: 1;
  height: ${variables.sidenavWidth};
  align-items: center;
  width: calc(100vw - ${variables.sidenavWidth});
  ${responsive.mobile`
    width: calc(100vw - ${variables.sidenavWidthSM});
  `}
  display: flex;
  align-items: center;
  .div {
    margin: 0 auto;
    max-width: 108rem;
    width: 100%;
    & > :not(:last-child) {
      margin-right: 5.2rem;
    }
  }
`;

export const FooterStyles = styled.footer`
  border-top: ${variables.borderStyle};
  background: #fff;
  position: fixed;
  bottom: 0;
  z-index: 10;
  max-height: ${variables.sidenavWidth};
  overflow: hidden;
  box-sizing: content-box;

  ${responsive.mobile`
    max-height: ${variables.sidenavWidthSM};
  `}
  .first-container {
    animation: ${fadeInTopReverse} 0.4s ease-in-out both;
    &.show {
      animation: ${fadeInTop} 0.4s ease-in-out both;
    }
  }
  .second-container {
    animation: ${fadeInBottomReverse} 0.4s ease-in-out both;
    &.show {
      animation: ${fadeInBottom} 0.4s ease-in-out both;
    }
    display: flex;
    max-height: ${variables.sidenavWidth};
    ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `}

    ${responsive.tabMid`
          flex-direction; column;
    `}
    .first-column {
      padding: 3.2rem 3rem;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.02em;
      color: ${black[100]};

      ${responsive.tabMid`
          display: none;
      `}
    }
    .second-column {
      margin-left: auto;
      display: flex;
      align-items: center;
      .copy {
        display: none;
        padding: 1rem 1.5rem;
      }
      ${responsive.tabMid`
          flex-direction: column;
          margin-left: unset;
          align-items: unset;
          width: 100%;
          .copy {
              display: block;
          }
      `}
      .socials {
        display: flex;
        margin-right: 5.2rem;
        ${responsive.tabMid`
              padding: 2.6rem 1.5rem;
              margin-right: unset;
              border-bottom: ${variables.borderStyle};
          `}
        & > :first-child {
          font-family: "Shapiro";
          margin-left: 2.3rem;
          font-weight: 300;
          font-size: 1.2rem;
          line-height: 1.8rem;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          ${responsive.tabMid`
              display: none;
          `}
          color: ${black[100]};
        }
        & > :not(:last-child) {
          margin-right: 5.2rem;
        }
      }
      .hello {
        background: ${black[100]};
        height: 100%;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 2.1rem;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-decoration: none;
        padding: 0 3rem.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: ${grey[100]};
        ${responsive.tabMid`
          display: block;
          padding: 2.6rem 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-center: center;
      `}
        span:not(:first-child) {
          color: ${white[100]};
        }
      }
    }
  }
`;
