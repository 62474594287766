import React, { useState } from "react";
import {
  HeaderStyle,
  SecondColumnStyle,
  HeaderLinkStyle,
  FooterStyles,
  SidebarStyles,
  HeaderLinkStyle2
} from "./header.style.jsx";
import { ReactComponent as MenuIcon } from "assets/icons/svg/menu-icon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/svg/close-icon.svg";
import { ReactComponent as ArrowRight } from "assets/icons/svg/arrow-right.svg";
import { ReactComponent as Twitter } from "assets/icons/svg/twitter.svg";
import { ReactComponent as LinkedIn } from "assets/icons/svg/linkedin.svg";
import { ReactComponent as Instagram } from "assets/icons/svg/instagram.svg";
import { ReactComponent as Youtube } from "assets/icons/svg/youtube.svg";
import { NavLink } from "react-router-dom";
export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <HeaderStyle>
      <SecondColumnStyle>
        <HeaderLinkStyle to="/" exact activeClassName="isActive">
          Home
        </HeaderLinkStyle>
        <HeaderLinkStyle to="/about" exact activeClassName="isActive">
          about us
        </HeaderLinkStyle>
        <HeaderLinkStyle to="/store" exact activeClassName="isActive" hidden>
          store
        </HeaderLinkStyle>
        {!isOpen && (
          <HeaderLinkStyle to="/sponsor" className="bg-blue show-sm">
            sponsor us
          </HeaderLinkStyle>
        )}
        <HeaderLinkStyle to="/" className="menu" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <React.Fragment>
              <CloseIcon />
              <span>Close</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <MenuIcon />
              <span>menu</span>
            </React.Fragment>
          )}
        </HeaderLinkStyle>
      </SecondColumnStyle>
      <SidebarStyles className={`${isOpen ? "open" : ""}`}>
        <ul className="list">
          <li className="list__item">
            <NavLink to="/about" className="list__link">
              <span>About us</span>
              <ArrowRight />
            </NavLink>
            {/* <a to={"/about"} className="list__link">
              <span>About us</span>
              <ArrowRight />
            </a> */}
          </li>
          <li className="list__item">
            <a href="#" className="list__link">
              <span>Shop Àṣà Merch</span>
              <ArrowRight />
            </a>
          </li>
        </ul>
        <FooterStyles>
          <div className="socials">
            <a href="https://twitter.com/AsaCoterie" rel="noreferrer" target="_blank" className="social-link">
              <Twitter />
            </a>
            <a
              href="https://www.linkedin.com/company/asadsgn/"
              rel="noreferrer"
              target="_blank"
              className="social-link"
            >
              <LinkedIn />
            </a>
            <a href="https://instagram.com/asacoterie" rel="noreferrer" target="_blank" className="social-link">
              <Instagram />
            </a>
            <a
              href="https://www.youtube.com/channel/UCHJ5YiYKzsrEZg9Zlmc__MA"
              rel="noreferrer"
              target="_blank"
              className="social-link"
            >
              <Youtube />
            </a>
          </div>
          <p className="copy copy--sm">© Àṣà Design Haus Ltd, 2021</p>
          <a href="mailto:hello@asadsgn.com?subject=Hello%20Asa" rel="noreferrer" target={"_blank"} className="hello">
            <span>say:</span>
            <span>hello@asadsgn.com</span>
          </a>
        </FooterStyles>
      </SidebarStyles>
    </HeaderStyle>
  );
}
