import styled, { css, keyframes } from "styled-components";
import { black, purple, green, yellow, red, beige, white, grey, blue } from "theme/colors";
import * as variables from "theme/variables";
import { responsive } from "theme";

const ContainerStyles = css`
  max-width: 108rem;
  @media only screen and (max-width: 1080px) {
    max-width: 100%;
    padding: 0 3rem;
  }
`;

const headingStyles = css`
  .heading {
    font-family: "Migra";
    font-weight: normal;
    font-size: 4.4rem;
    line-height: 110%;
    color: ${black[100]};
    max-width: 43.2rem;
    margin-top: 6.4rem;
    margin-bottom: 1.2rem;
    max-height: ${variables.sidenavWidth};
    ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `}
  }
  .sub-text {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: ${black[100]};
    max-width: 31.2rem;
    margin-bottom: 4.8rem;
  }
`;
export const HorizontalRuleStyle = styled.hr`
  border: 0;
  border-bottom: ${variables.borderStyle};
  margin: unset;
`;

export const HeroStyles = styled.div`
  ${ContainerStyles}
  margin: 9.9rem auto;
  margin-top: calc(${variables.sidenavWidth} + 9.9rem);
  ${responsive.mobile`
    margin-top: calc(${variables.sidenavWidthSM} + 9.9rem);
  `}
  display: flex;

  .btn--primary {
    width: fit-content;
  }
  & > :not(:last-child) {
    margin-right: 11.3rem;
  }
  .column-1 {
  }
  .column-2 {
    position: relative;
    flex: 1;
  }
  .heroImg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    ${responsive.tabMid`
    display: none;
  `}
  }
  ${responsive.tabMid`
    flex-direction: column;
    & > :not(:last-child) {
      margin-right: unset;
      margin-bottom: 2rem;
    }
  `}
  .hero {
    &__main-text {
      font-family: "Migra";
      font-weight: 200;
      font-size: 12rem;
      line-height: 14.5rem;
      letter-spacing: -0.02em;
      margin: unset;
      /* margin-bottom: 4.7rem; */
      color: ${black[100]};
      span {
        color: ${red[100]};
      }
      ${responsive.tabMid`
        margin-bottom: 2rem;
        font-size: 6.2rem;
        line-height: 90%;
    `}
    }
    &__brief {
      /* font-family: "Inter"; */
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 200%;
      max-width: 48.4rem;
      color: ${black[100]};
      margin-bottom: 4.4rem;
    }
    &__hint {
      margin-top: 5rem;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 200%;
      letter-spacing: 0.02em;
      color: ${black[100]};
    }
  }
`;

export const SectionOneStyles = styled.section`
  ${ContainerStyles}
  margin: 13.6rem auto;
  display: flex;
  flex-direction: column;
  .first-section {
    &--one {
      width: 66%;
      font-family: Migra;
      font-style: normal;
      font-weight: 200;
      font-size: 4.5rem;
      line-height: 140%;
      letter-spacing: -0.01em;
      color: ${black[100]};
      ${responsive.tabMid`
        width: 100%;
        font-size: 3.2rem;
    `}
    }
    &--two {
      width: 36%;
      font-size: 1.6rem;
      line-height: 190%;
      letter-spacing: 0.02em;
      color: ${black[100]};
      margin-left: auto;

      ${responsive.tabMid`
        width: 100%;
        margin: unset;
    `}
    }
  }
`;

export const SectionTwoStyles = styled.section`
  ${ContainerStyles}
  max-width: unset;
  margin-left: calc((100% - 108rem) / 2);
  margin-right: unset;
  display: flex;

  ${responsive.tabMid`
      margin: unset;
      flex-direction: column;
      padding: unset;
  `}
  .column {
    margin-right: 20rem;
    &-1 {
      max-width: 51.7rem;
      padding-top: 6.4rem;
      ${responsive.tabMid`
        max-width: unset;
        padding: 6.4rem 3rem;
      `}
      &__heading {
        font-family: Migra;
        font-style: normal;
        font-weight: normal;
        font-size: 4.4rem;
        line-height: 110%;
        color: ${black[100]};
        margin-bottom: 1.2rem;
      }
      &__brief {
        font-size: 1.6rem;
        line-height: 190%;
        letter-spacing: 0.02em;
        color: ${black[100]};
        margin-left: auto;
      }
    }
    &-2.grid {
      width: 54rem;
      height: 54rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      border-left: ${variables.borderStyle};
      margin-left: auto;

      ${responsive.tabMid`
        width: 100%;
        border-left: unset;
        border-top: ${variables.borderStyle};
      `}

      ${responsive.mobile`
        height: 35rem;
      `}
      .box {
        background: #f0e9e5;
        width: 100%;
        height: 100%;
        border-right: ${variables.borderStyle};
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .box--1,
      .box--2 {
        border-bottom: ${variables.borderStyle};
      }
      .box--3 {
        grid-column: 1 / -1;
      }
    }
  }
`;
export const SectionThreeStyles = styled.section`
  ${ContainerStyles}
  max-width: unset;
  margin-left: calc((100% - 108rem) / 2);
  margin-right: unset;
  display: flex;

  ${responsive.tabMid`
      margin: unset;
      flex-direction: column;
      padding: unset;
  `}
  .column {
    margin-right: 20rem;
    &-1 {
      max-width: 51.7rem;
      padding-top: 6.4rem;

      ${responsive.tabMid`
        max-width: unset;
        padding: 6.4rem 3rem;
      `}
      &__heading {
        font-family: Migra;
        font-style: normal;
        font-weight: normal;
        font-size: 4.4rem;
        line-height: 110%;
        color: ${black[100]};
        margin-bottom: 1.2rem;
      }
      &__brief {
        font-size: 1.6rem;
        line-height: 190%;
        letter-spacing: 0.02em;
        color: ${black[100]};
        margin-left: auto;
      }
    }
    &-2.grid {
      width: 54rem;
      height: 54rem;
      display: grid;
      grid-template-columns: 36rem;
      grid-template-rows: repeat(4, 1fr);
      border-left: ${variables.borderStyle};
      margin-left: auto;

      ${responsive.tabMid`
        width: 100%;
        border-left: unset;
        border-top: ${variables.borderStyle};
        grid-template-columns: 1fr;
      `}
      .box {
        font-family: "Inter";
        text-decoration: none;
        background: #f0e9e5;
        width: 100%;
        height: 100%;
        border-right: ${variables.borderStyle};
        padding: 1.6rem 1.8rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .top {
          display: flex;
          justify-content: flex-end;
        }
        .bottom {
          /* font-weight: 500; */
          font-size: 2rem;
          line-height: 2.4rem;
          letter-spacing: -0.01em;
          text-transform: capitalize;

          color: ${black[100]};
          margin-top: auto;
        }
      }
      .box--1,
      .box--2,
      .box--3 {
        border-bottom: ${variables.borderStyle};
      }
      .box--1 {
        background: ${red[50]};
      }
      .box--2 {
        background: ${purple[100]};
      }
      .box--3 {
        background: ${green[200]};
      }
      .box--4 {
        background: ${yellow[100]};
      }
    }
  }
`;

const scrolling = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1417px);
  }
`;
export const Scrolling = styled.section`
  padding: 3rem 0;
  background: ${beige[100]};
  .text {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    & > * {
      display: inline-block;
      animation: ${scrolling} 20s infinite linear both;
    }
    .chunk {
      & > * {
        margin-right: 1.2rem;
      }
    }
  }
`;

export const SectionFourStyles = styled.section`
  ${ContainerStyles}
  max-width: unset;
  margin-left: calc((100% - 108rem) / 2);
  margin-right: unset;
  display: flex;
  ${responsive.tabMid`
      margin: unset;
      flex-direction: column;
      padding: unset;
  `}
  .column {
    margin-right: 20rem;
    &-1 {
      max-width: 51.7rem;
      padding-top: 6.4rem;

      ${responsive.tabMid`
        max-width: unset;
        padding: 6.4rem 3rem;
      `}
      &__heading {
        font-family: Migra;
        font-style: normal;
        font-weight: normal;
        font-size: 4.4rem;
        line-height: 110%;
        color: ${black[100]};
        margin-bottom: 1.2rem;
      }
      &__brief {
        font-size: 1.6rem;
        line-height: 190%;
        letter-spacing: 0.02em;
        color: ${black[100]};
        margin-left: auto;
      }
    }
    &-2.grid {
      width: 71.8rem;
      height: 71.8rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
      border-left: ${variables.borderStyle};
      margin-left: auto;

      ${responsive.tabMid`
        width: 100%;
        border-left: unset;
        border-top: ${variables.borderStyle};
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 2px;
      `}
      .box {
        width: 100%;
        height: 100%;
        border-right: ${variables.borderStyle};
        border-bottom: ${variables.borderStyle};
        &.can-animate {
          position: relative;
          cursor: pointer;
          .container {
            display: flex;
            flex-direction: column;
            padding: 1.3rem 0 0 1.3rem;
            background: ${black[100]};
            position: absolute;
            width: 100%;
            height: 100%;
            transition: width 0.3s ease-in-out;
            overflow: hidden;
            .top {
              display: flex;
              & > * {
                flex: 1;
              }
              .heading {
                font-family: Shapiro;
                font-style: normal;
                font-weight: normal;
                font-size: 2rem;
                line-height: 120%;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: ${white[200]};
              }
              .brief {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 150%;
                color: ${white[200]};
                opacity: 0;
                height: 0;
              }
            }
          }
          .bottom {
            display: flex;
            justify-content: flex-end;
            margin-top: auto;
            path {
              opacity: 1;
            }
          }
          &:not(:hover) {
            .container {
              .brief {
                transition: unset;
                opacity: 0;
              }
            }
          }
          &:hover {
            .container {
              width: calc(200% + 2px);
              .brief {
                display: block;
                transition: opacity 0.2s ease-in 0.3s;
                opacity: 1;
                height: auto;
              }
            }
            svg {
              transition: all 0.3s ease-in-out;
              transform: scale(0.5);
              transform-origin: bottom right;
            }
          }
        }
      }
      .box--13,
      .box--14,
      .box--15,
      .box--16 {
        border-bottom: unset;
      }

      ${responsive.tabMid`
        .box{
          border: unset;
        }
        .can-animate {

          &:not(:hover),
          &:hover {
            .container {
              width: calc(200% + 2px);
              padding: 1.3rem;
              .heading{
                font-size: 1.6rem;
              }
              .brief {
                display: block;
                transition: opacity 0.2s ease-in 0.3s;
                opacity: 1 !important;
                height: auto !important;
              }
            }
            svg {
              transition: all 0.3s ease-in-out;
              transform: scale(0.5);
              transform-origin: bottom right;
              display: none;
            }
          }
        }
        .box--3{
          grid-row: 1 / 2;
          grid-column: 1 / 2;
        }
        .box--6{
          grid-row: 2 / 3;
          grid-column: 1 / 2;
        }
        .box--9{
          grid-row: 3 / 4;
          grid-column: 1 / 2;
        }
        .box--15{
          grid-row: 4 / -1;
          grid-column: 1 / 2;
        }
      `}
      ${responsive.mobile`
        .container .top{
          flex-direction: column;
          & >:not(:last-child){
            margin-bottom: 2rem;
          }
        }
      `}
    }
  }
`;
export const SectionFiveStyles = styled.section`
  ${headingStyles};
  .heading {
    display: block;
    padding: 2rem 0;
    margin: 0 auto;
    max-width: 108rem;

    ${responsive.tabMid`
      padding: 2rem;
  `}
  }
  .grid {
    max-width: 108rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-left: ${variables.borderStyle};
    ${responsive.tabMid`
      grid-template-columns: 1fr;
      border-left: unset;
  `}
    .person {
      border-right: ${variables.borderStyle};
      border-bottom: ${variables.borderStyle};
      position: relative;
      &:nth-last-child(-n + 3) {
        border-bottom: unset;
        ${responsive.tabMid`
            border-bottom: ${variables.borderStyle};
        `}
      }
      &:last-child {
        ${responsive.tabMid`
        border-bottom: unset;
        `}
      }
      &__image {
        width: 100%;
        height: 35.6rem;
        background: ${beige[100]};
        border-bottom: ${variables.borderStyle};
        overflow: hidden;
        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* object-position: top; */
        }
      }
      &__details {
        padding: 1.6rem 2rem;
        padding-bottom: 3.5rem;
      }
      &__name {
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 120%;
        letter-spacing: -0.04em;

        color: ${black[100]};
      }
      &__title {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 120%;
        color: ${black[100]};
        margin-bottom: 1.2rem;
      }
      &__role {
        font-size: 1.6rem;
        line-height: 1.9rem;
        letter-spacing: 0.02em;
        color: ${black[100]};
      }
      &__contact {
        position: absolute;
        bottom: 0;
        right: 0;
        border-top: ${variables.borderStyle};
        border-left: ${variables.borderStyle};
        ${responsive.mobile`
            position: static;
            width: fit-content;
            margin-left: auto;
        `}
        & > * {
          padding: 1.4rem;
          text-decoration: none;
          display: inline-flex;
          cursor: pointer;
        }
        & > :not(:last-child) {
          border-right: ${variables.borderStyle};
        }
        .twitter:hover,
        .linkedin:hover {
          background: ${blue[200]};
          transition: all 0.2s ease-in-out;
          path {
            transition: all 0.2s ease-in-out;
            fill: ${black[100]};
          }
        }
        .linkedin:hover {
          background: ${blue[100]};
        }
      }
    }
  }
  .grid-2 {
    max-width: 108rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-left: ${variables.borderStyle};
    border-right: ${variables.borderStyle};

    ${responsive.tabMid`
      grid-template-columns: 1fr;
      border-left: unset;
      border-right: unset;
    `}
    .support {
      display: flex;
      padding: 2.4rem 2.4rem 0 2.4rem;
      align-items: flex-start;
      &__heading {
        font-weight: 600;
        font-size: 2.2rem;
        line-height: 2.7rem;
        letter-spacing: -0.02em;
        color: ${black[100]};
      }
      &__link {
        width: 100%;
        display: flex;
        align-items: flex-start;
        font-family: "Inter";
        font-weight: 500;
        font-size: 1.4rem;
        text-decoration: none;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: ${black[100]};
      }
      &__icon {
        margin-left: auto;
        transform: rotate(-90deg);
        path {
          stroke: ${grey[300]};
          fill: ${grey[300]};
        }
      }
    }
    .person {
      display: flex;
      padding: 2.4rem 2.4rem 0 2.4rem;
      &:nth-child(-n + 2) {
        padding-top: 4.8rem;
      }
      &:nth-last-child(-n + 2) {
        .person__details,
        .person__contact {
          border-bottom: unset;

          ${responsive.tabMid`
            border-bottom: ${variables.borderStyle};
          `}
        }
      }
      &:last-child {
        .person__details,
        .person__contact {
          ${responsive.tabMid`
            border-bottom: unset !important;
          `}
        }
      }
      &:nth-child(odd) {
        border-right: ${variables.borderStyle};
        ${responsive.tabMid`
          border-right: unset;
        `}
      }
      &__details {
        flex: 1;
        padding-bottom: 1.6rem;
        border-bottom: ${variables.borderStyle2};
      }
      &__name {
        font-size: 2.2rem;
        line-height: 2.7rem;
        letter-spacing: -0.01em;
        color: ${black[100]};
      }
      &__title {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: ${black[100]};
      }
      &__contact {
        padding-bottom: 1.6rem;
        border-bottom: ${variables.borderStyle2};
        & > * {
          padding: 1.4rem;
          text-decoration: none;
          display: inline-flex;
          cursor: pointer;
        }
        & > :not(:last-child) {
          margin-right: 0.8rem;
        }
      }
    }
  }
`;
