import React from "react";
import { SponsorStyles, HorizontalRuleStyle } from "./sponsor.styles";
import Marquee from "components/marquee/marquee.component";
import Testimonials from "components/testimonials/testimonials.component";
import Contact from "components/contact/contact.component";
import Trustee from "components/trustee/trustee.component";
import Gallery from "components/gallery/gallery.component";
import { ReactComponent as PinkFlowerSM } from "assets/images/svg/flower-pink-sm.svg";
import { ReactComponent as BlueFlowerSM } from "assets/images/svg/flower-blue-sm.svg";
export default function Sponsor() {
  return (
    <SponsorStyles>
      <div className="hero">
        <p className="hero__main-text">
          <span>
            Support <span className="text-md">collaboration</span>
          </span>

          <div className="hero__slider">
            <div className="hero__slider-item">design</div>
            <div className="hero__slider-item">craft</div>
            <div className="hero__slider-item">collaboration</div>
            <div className="hero__slider-item">curiosity</div>
            <div className="hero__slider-item">careers</div>
            <div className="hero__slider-item">culture</div>
          </div>
        </p>
        <p className="hero__sub-text">
          We are on a journey to spread the culture of an informed execution of good design in society and business, and
          we want you to join us.
        </p>

        <a className="btn btn--primary" href="#donate">
          support our initiatives
        </a>
      </div>

      <HorizontalRuleStyle />
      <Gallery />

      <Marquee text="Design is One" />
      <HorizontalRuleStyle />
      <Trustee />

      <Marquee text="supporting design" />
      <HorizontalRuleStyle />
      <div className="donate" id="donate">
        <div className="card card--1">
          <PinkFlowerSM className="icon" />
          <div className="img-container">
            <img
              src={require("../../../../assets/images/png/diverse02-1.5x.png").default}
              srcSet={`${require("../../../../assets/images/png/diverse02-1.5x.png").default} 1.5x, ${
                require("../../../../assets/images/png/diverse02-3x.png").default
              } 3x`}
              alt="person image"
              className="image"
            />
          </div>
          <p className="heading">Donate to the Coterie</p>
          <p className="sub-text">
            Sponsor our community & education projects and events. Help us equip designers with needed knowledge and
            tools.
          </p>
          <div className="footer">
            <button className="btn btn--outline">wire transfer</button>
            <button className="btn btn--outline">with CRYPTO</button>
            <button className="btn btn--outline">card donation</button>
          </div>
        </div>
        <div className="card card--2">
          <BlueFlowerSM className="icon" />
          <div className="img-container">
            <img
              src={require("../../../../assets/images/png/diverse03-1.5x.png").default}
              alt="persons image"
              srcSet={`${require("../../../../assets/images/png/diverse03-1.5x.png").default} 1.5x, ${
                require("../../../../assets/images/png/diverse03-3x.png").default
              } 3x`}
              className="image"
            />
          </div>
          <p className="heading">Become a partner</p>
          <p className="sub-text">
            Join us to build an inclusive and multidisciplinary design community that is ethical, socially and
            culturally aware, collaborative, and sincere.
          </p>
          <div className="footer">
            <button className="btn btn--outline">START A CONVERSATION</button>
          </div>
        </div>
      </div>
      <HorizontalRuleStyle />
      <Marquee text="supporting design" />
      <HorizontalRuleStyle />
      <Testimonials />
      <Contact />
    </SponsorStyles>
  );
}
