import styled, { css, keyframes } from "styled-components";

import * as variables from "theme/variables";
import { white, black, grey, beige, yellow, green } from "theme/colors";
import { responsive } from "theme";

const ContainerStyles = css`
  max-width: 108rem;
  @media only screen and (max-width: 1080px) {
    max-width: 100%;
    padding: 0 3rem;
  }
`;

const headingStyles = css`
  .heading {
    font-family: "Migra";
    font-weight: normal;
    font-size: 4.4rem;
    line-height: 110%;
    color: ${black[100]};
    max-width: 40.3rem;
    ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `}
  }
  .sub-text {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 160%;
    letter-spacing: 0.02em;
    max-width: 40.3rem;
    color: ${black[100]};
  }
`;

export const HorizontalRuleStyle = styled.hr`
  border: 0;
  border-bottom: ${variables.borderStyle};
  margin: unset;
`;

export const GalleryStyles = styled.div`
  /* &.gallery {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 36rem);

    ${responsive.tabMid`
      grid-template-columns: unset;
      &__item{
        grid-column: unset !important;
        grid-row: unset !important;
      }
    `}
    position: relative;
    .pink,
    .logo {
      position: absolute;
    }
    .pink {
      bottom: 0;
      left: 25%;
      transform: translate(-50%, 50%);
    }
    .logo {
      bottom: 50%;
      right: 8.333%;
      transform: translate(50%, 50%);
    }
    .heroImg {
      position: absolute;
      top: -214px;
      right: 6.6rem;

      ${responsive.tabMid`
          top: -171px;
        right: -74px;
        transform: scale(0.5);
      `}
    }
    .gallery__item {
      background: ${beige[100]};
      border-right: ${variables.borderStyle};
      border-bottom: ${variables.borderStyle};
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &--1 {
        grid-column: 1 / span 7;
        grid-row: 1 / 2;
      }
      &--2 {
        grid-column: 8 / -1;
        grid-row: 1 / 2;
      }
      &--3 {
        grid-column: 1 / 4;
        grid-row: 2 / -1;
      }
      &--4 {
        grid-column: 4 / 10;
        grid-row: 2 / -1;
      }
      &--5 {
        grid-column: 10 / -1;
        grid-row: 2 / -1;
      }
    }
  } */
  //GALLERY
  &.gallery {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 36rem);

    ${responsive.tabMid`
      grid-template-columns: 1fr;
      .gallery__item{
        grid-column: unset !important;
        grid-row: unset !important;
      }
    `}
    position: relative;
    .pink,
    .logo {
      position: absolute;
    }
    .pink {
      bottom: 0;
      left: 25%;
      transform: translate(-50%, 50%);
    }
    .logo {
      bottom: 50%;
      right: 8.333%;
      transform: translate(50%, 50%);
    }
    .heroImg {
      position: absolute;
      top: -214px;
      right: 6.6rem;

      ${responsive.tabMid`
          top: -171px;
        right: -74px;
        transform: scale(0.5);
      `}
    }
    .gallery__item {
      background: ${beige[100]};
      border-right: ${variables.borderStyle};
      border-bottom: ${variables.borderStyle};
      overflow: hidden;
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &--1 {
        grid-column: 1 / span 7;
        grid-row: 1 / 2;
      }
      &--2 {
        grid-column: 8 / -1;
        grid-row: 1 / 2;
      }
      &--3 {
        grid-column: 1 / 4;
        grid-row: 2 / -1;
      }
      &--4 {
        grid-column: 4 / 10;
        grid-row: 2 / -1;
      }
      &--5 {
        grid-column: 10 / -1;
        grid-row: 2 / -1;
      }
    }
  }
`;
