// ScrollToTop.jsx
import React, { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = props => {
  const location = useLocation();
  const { container } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
    container.current.scrollTo(0, 0);
  }, [location]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default ScrollToTop;
