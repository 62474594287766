import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import useWindowSize from "utils/windowResize";
import { testimonials } from "./testimonials";
const SwiperSlider = props => {
  const [width, height] = useWindowSize();

  SwiperCore.use([Navigation]);
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={width > 1020 ? 4 : "auto"}
      grabCursor={true}
      loop={true}
      navigation={{
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev"
      }}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <div className="cards__item">
            <p className="text">{testimonial.comment}</p>
            <div className="cards__footer">
              <img src={testimonial.image} alt={testimonial.name + " image"} className="userImage" />
              <div className="right-col">
                <span className="name">{testimonial.name}</span>
                <span className="designation">{testimonial.role}</span>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
      {/* <SwiperSlide>
        <div className="cards__item">
          <p className="text">
            <sup>“</sup> I got into Asa when I was just about 2 months into Product design and from the very first day
            it was such a welcoming environment where designers I previously looked up to related with me like an equal
          </p>
          <span className="name">—&nbsp;Bukunmi Ajayiola</span>
          <span className="designation">Product Designer</span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="cards__item">
          <p className="text">
            <sup>“</sup> I got into Asa when I was just about 2 months into Product design and from the very first day
            it was such a welcoming environment where designers I previously looked up to related with me like an equal
          </p>
          <span className="name">—&nbsp;Bukunmi Ajayiola</span>
          <span className="designation">Product Designer</span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="cards__item">
          <p className="text">
            <sup>“</sup> I got into Asa when I was just about 2 months into Product design and from the very first day
            it was such a welcoming environment where designers I previously looked up to related with me like an equal
          </p>
          <span className="name">—&nbsp;Bukunmi Ajayiola</span>
          <span className="designation">Product Designer</span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="cards__item">
          <p className="text">
            <sup>“</sup> I got into Asa when I was just about 2 months into Product design and from the very first day
            it was such a welcoming environment where designers I previously looked up to related with me like an equal
          </p>
          <span className="name">—&nbsp;Bukunmi Ajayiola</span>
          <span className="designation">Product Designer</span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="cards__item">
          <p className="text">
            <sup>“</sup> I got into Asa when I was just about 2 months into Product design and from the very first day
            it was such a welcoming environment where designers I previously looked up to related with me like an equal
          </p>
          <span className="name">—&nbsp;Bukunmi Ajayiola</span>
          <span className="designation">Product Designer</span>
        </div>
      </SwiperSlide> */}

      {props.children}
    </Swiper>
  );
};

export default SwiperSlider;
