export const primaryFont = "Inter, sans-serif";

export const captionFont = '"Open Sans", sans-serif';

export const typeScale = {
  header1: "3.5rem",
  header2: "2.6rem",
  header3: "2.0rem",
  header4: "1.8rem",
  header5: "1.4rem",
  bodyText1: "1.6rem",
  bodyText2: "1.4rem",
  bodyText3: "1.2rem",
  bodyText4: "1.0rem"
};
