import React from "react";
import {
  HeroStyles,
  HorizontalRuleStyle,
  SectionOneStyles,
  SectionTwoStyles,
  SectionThreeStyles,
  SectionFourStyles,
  SectionFiveStyles
} from "./about.styles";
import { ReactComponent as ArrowDiagonalUpRight } from "assets/icons/svg/arrow-diagonal-md-up-right.svg";
import { ReactComponent as ArrowDiagonalDownRight } from "assets/icons/svg/arrow-diagonal-lg-down-right.svg";
import { ReactComponent as Twitter } from "assets/icons/svg/twitter.svg";
import { ReactComponent as LinkedIn } from "assets/icons/svg/linkedin.svg";
import { ReactComponent as HeroImg } from "assets/images/svg/hero.svg";
import Contact from "components/contact/contact.component";
import Marquee from "components/marquee/marquee.component";
export default function Home() {
  const personsGridOne = [
    {
      name: "Caleb Uzuegbunam",
      title: "Experience Designer",
      role: "Founder/Team Lead",
      twitter: "https://twitter.com/abstractonion?s=20",
      linkedin: "https://www.linkedin.com/in/calebuzu",
      avatar:
        "https://lh6.googleusercontent.com/Id5naSatTlkAKiWRLFeRw9g3kU0jCFjRStnU9jD2Zauqs6lRTF6p10rrDnZsitzVzG4=w2400"
    },
    {
      name: "Osaz Ehiabhi",
      title: "Product Designer, Big Cabal",
      role: "Community and Education",
      twitter: "https://twitter.com/osazeyyh?s=20",
      linkedin: "https://www.linkedin.com/in/osazeyyh",
      avatar:
        "https://lh3.googleusercontent.com/n84BO5vRDNHBGTS9zyQf6L3FzOVPBjo3ekStybwUXW__opcQF_ELK-_82fwf5nvhU3o=w2400Í"
    },
    {
      name: "Busayo Sunmonu",
      title: "Product Designer",
      role: "Operations and Partnerships",
      twitter: "https://twitter.com/_busayosunmonu",
      linkedin: "https://www.linkedin.com/in/oluwabusayomi-sunmonu-242b93142",
      avatar:
        "https://lh3.googleusercontent.com/ms5YiW68dynwFlwtWaLy7kuG-DkXCSRyO8w7KmtKU4Ks0k6uFrB8nejw3D20LaRgcAg=w2400"
    },
    {
      name: "John Wright-Nyingifa",
      title: "Extended Reality (XR) Designer",
      role: "Communications",
      twitter: "https://twitter.com/I_triple9?s=20",
      linkedin: "https://www.linkedin.com/in/john-wright-nyingifa",
      avatar:
        "https://lh5.googleusercontent.com/mhhzpH3hvEe7JjXOg62PusZbXJAHHTVsbGjhmCgJlXddNHaE0qg5r6bsTEkIdONON2w=w2400"
    },
    {
      name: "Kola Ojoodide",
      title: "Product Designer, Bento",
      role: "Talent Resources",
      twitter: "https://twitter.com/Ighalo_design?s=20",
      linkedin: "https://www.linkedin.com/in/kola-ojoodide",
      avatar:
        "https://lh6.googleusercontent.com/CTbefWWge3E7hn47Zl_dx83Q7EeUszYiYdCOiST9buIvAOpkYpOv_F1M40O8zUZy5rU=w2400"
    },
    {
      name: "Mano Iluezi-Ogbaudu",
      title: "Product designer, Kuda",
      role: "Media and Content",
      twitter: "https://twitter.com/onowomano?s=20",
      linkedin: "https://www.linkedin.com/in/onowomano-iluezi-ogbaudu-671746152",
      avatar:
        "https://lh6.googleusercontent.com/7YtIVv87fTw6q5Ov4wH6n1NniaxTOYf8HPcPiqgO6QMsqQ5wvU3I2Pxzvkk6bicOVz0=w2400"
    },
    {
      name: "Aanu Sebiomo",
      title: "Product Designer, Voyance",
      role: "Media and Content",
      twitter: "https://twitter.com/sebiomo_?s=20",
      linkedin: "https://www.linkedin.com/in/sebiomo",
      avatar:
        "https://lh4.googleusercontent.com/aXl4fV_B_3GU3jzqmA_sk_xOLZHxGqeXA4yg_tSjU3q1k7PKAn1TnGGKa6R2O7UCtHQ=w2400"
    },
    {
      name: "Gabriel Uzuegbunam",
      title: "Creative Director, Mbarihaus",
      role: "Creative Direction",
      twitter: "https://twitter.com/slumhabibii",
      linkedin: "#",
      avatar:
        "https://lh6.googleusercontent.com/vA8EI0sXGrCReqIAUkHjgyXRtLJ-oF5p_xKaeyMLYackjeDLdlVQdhc7_uOQXt46KxQ=w2400"
    },
    {
      name: "Mudia Imasuen",
      title: "Product Design Lead, Bloc",
      role: "Advisory, Partnerships",
      twitter: "https://twitter.com/imasuen_design?s=20",
      linkedin: "https://www.linkedin.com/in/imasuen",
      avatar:
        "https://lh4.googleusercontent.com/Hni0U6l-iu_ahS1P4K4lf24IknchBqavqcGONf94tw1f2qhM-cQ_Udx08K-57sUjZlw=w2400"
    }
    // {
    //   name: "Dami Oludumila",
    //   title: "User Researcher, Webflow",
    //   role: "Advisory, Education",
    //   twitter: "https://twitter.com/LongLiveDumi?s=20",
    //   linkedin: "https://www.linkedin.com/in/damilolaoludumila"
    // }
  ];
  const personsGridTwo = [
    {
      name: "Jemima Oluwadarasimi",
      title: "Product Designer, Paystack",
      twitter: "https://twitter.com/daraassimita?s=20",
      linkedin: "https://www.linkedin.com/in/daraassimita"
    },
    {
      name: "Seun Badejo",
      title: "Brand Designer, Dá Design Studio",
      twitter: "https://twitter.com/Xeunbadejo?s=20",
      linkedin: "https://www.linkedin.com/in/designbeast"
    },
    {
      name: "Dumebi Iwuchukwu",
      title: "Head of Design, Big Cabal",
      twitter: "https://twitter.com/DUM3BI?s=20",
      linkedin: "https://www.linkedin.com/in/chukwudumebi"
    },
    {
      name: "Daniel Abudu",
      title: "Visual and Product Designer",
      twitter: "https://twitter.com/unorthodox_dan?s=20",
      linkedin: "https://www.linkedin.com/in/daniel-abudu"
    },
    {
      name: "Dami Oludumila",
      title: "User Researcher, Webflow",
      twitter: "https://twitter.com/LongLiveDumi?s=20",
      linkedin: "https://www.linkedin.com/in/damilolaoludumila"
    },
    // {
    //   name: "Daniel Abayomi",
    //   title: "Senior Product Designer, Brass",
    //   twitter: "https://twitter.com/pixelDahn?s=20",
    //   linkedin: "https://www.linkedin.com/in/daniel-abayomi-069480161"
    // },
    {
      name: "Eronmonsele Okojie",
      title: "Product Designer, Bloc",
      twitter: "https://twitter.com/eronss_?s=20",
      linkedin: "https://www.linkedin.com/in/eronmonsele-okojie-805702149"
    },
    {
      name: "Aderinsola Oluwafemi",
      title: "Product Designer, Eden Life",
      twitter: "https://twitter.com/Aderinsola_O?s=20",
      linkedin: "https://www.linkedin.com/in/aderinsolaoluwafemi"
    },
    {
      name: "Quadri Agboluaje",
      title: "Product and Marketing Designer",
      twitter: "https://twitter.com/QuadriAgboluaje?s=20",
      linkedin: "https://www.linkedin.com/in/quadri-agboluaje-7a866789"
    },
    {
      name: "Victory Oki",
      title: "UX Developer, LawPavillon",
      twitter: "https://twitter.com/Victory_oki?s=20",
      linkedin: "https://www.linkedin.com/in/victoryoki"
    },
    {
      name: "Charles Njoku",
      title: "Co-founder, Spirē",
      twitter: "https://twitter.com/madebycharles?s=20",
      linkedin: "https://www.linkedin.com/in/madebycharles"
    },
    {
      name: "Chukwuka Ezeoke",
      title: "Co-founder, Spirē",
      twitter: "https://twitter.com/_shyone?s=20",
      linkedin: "https://www.linkedin.com/in/chukwuka-ezeoke"
    },
    {
      name: "Praise Philemon",
      title: "Brand & Comms Lead, Bloc",
      twitter: "https://twitter.com/PraisePhilemonn?s=20",
      linkedin: "https://www.linkedin.com/in/praisephilemon"
    },
    {
      name: "Liz Agboola",
      title: "Graphic Designer and Illustrator",
      twitter: "https://twitter.com/LizAgboola?s=20",
      linkedin: "https://www.linkedin.com/in/lizagboola"
    }
  ];
  return (
    <div>
      <HeroStyles>
        <div className="column-1">
          <h4 className="hero__main-text">
            Àṣà is <span>Culture</span>
          </h4>
          <p className="hero__brief">
            Àṣà is a culture design experiment that seeks to organize, educate, and empower African designers of today
            to be ethical, socially and culturally aware, collaborative, and sincere.
          </p>
          <a className="btn btn--primary" href="#manifesto">
            Read the ÀSà manifesto
          </a>
          <p className="hero__hint">**Àṣà is Yoruba for “Culture”</p>
        </div>
        <div className="column-2">
          <HeroImg className="heroImg" />
        </div>
      </HeroStyles>
      <HorizontalRuleStyle />

      <SectionOneStyles id="manifesto">
        <p className="first-section--one">
          We believe that childlike curiosity and the optimism to see it through are the only genuine currency in the
          creative process.
        </p>
        <p className="first-section--two">
          Moreover, when two or more people come together, incredible things can happen, which is why we chose to create
          a platform that allows contributions by designers for designers.
        </p>
      </SectionOneStyles>
      <HorizontalRuleStyle />

      <SectionTwoStyles>
        <div className="column-1">
          <p className="column-1__heading">Building a diverse design community</p>
          <p className="column-1__brief">
            In 2018, we realized there were very few community efforts around encouraging design adoption in the
            Nigerian tech scene. Moreso, where they did exist, these community projects were either focused around a
            specific design field or tool.
            <br />
            We are building a community that is inclusive of all design fields and celebrates our cultural
            heritage—design for and by people who look like us.
          </p>
        </div>
        <div className="column-2 grid">
          <div className="box box--1">
            <img
              src={require("../../../../assets/images/png/diverse02-1.5x.png").default}
              alt="diversity image"
              srcSet={`${require("../../../../assets/images/png/diverse02-1.5x.png").default} 1.5x, ${
                require("../../../../assets/images/png/diverse02-3x.png").default
              } 3x`}
            />
          </div>
          <div className="box box--2">
            <img
              src={require("../../../../assets/images/png/diverse01-1.5x.png").default}
              alt="diversity image"
              srcSet={`${require("../../../../assets/images/png/diverse01-1.5x.png").default} 1.5x, ${
                require("../../../../assets/images/png/diverse01-3x.png").default
              } 3x`}
            />
          </div>
          <div className="box box--3">
            <img
              src={require("../../../../assets/images/png/diverse03-1.5x.png").default}
              alt="diversity image"
              srcSet={`${require("../../../../assets/images/png/diverse03-1.5x.png").default} 1.5x, ${
                require("../../../../assets/images/png/diverse03-3x.png").default
              } 3x`}
            />
          </div>
        </div>
      </SectionTwoStyles>
      <HorizontalRuleStyle />

      <SectionThreeStyles>
        <div className="column-1">
          <p className="column-1__heading">Investing in effective design education</p>
          <p className="column-1__brief">
            Over the past three years, while organizing a diverse design community, we recognized another gap in the
            tech ecosystem we needed to fill. This gave birth to Àṣà Education, a subsidiary of Àṣà Design built with
            the best curriculum to meet the industry&apos;s demand for excellent designers and secure its
            sustainability.
            <br />
            We have devoted our efforts to providing quality design education that is comprehensive, impactful, and
            accessible.
          </p>
        </div>
        <div className="column-2 grid">
          <a
            className="box box--1"
            href="https://www.youtube.com/playlist?list=PLIN9NLuy_J9KtvgdMY_rP0J13YSjZ_0jF"
            target={"_blank"}
            rel="noreferrer"
          >
            <p className="top">
              <ArrowDiagonalUpRight />
            </p>
            <p className="bottom">Àṣà Sessions</p>
          </a>
          <a className="box box--2" href="https://t.me/AsaDesignResources" target={"_blank"} rel="noreferrer">
            <p className="top">
              <ArrowDiagonalUpRight />
            </p>
            <p className="bottom">Àṣà Resources repository</p>
          </a>
          <a
            className="box box--3"
            href="https://www.youtube.com/playlist?list=PLIN9NLuy_J9K2Dy45mRbwFzKEYnas7hLA"
            target={"_blank"}
            rel="noreferrer"
          >
            <p className="top">
              <ArrowDiagonalUpRight />
            </p>
            <p className="bottom">TalkingDesignWith</p>
          </a>
          <a
            className="box box--4"
            href="https://cowrywise.com/blog/women-in-design/"
            target={"_blank"}
            rel="noreferrer"
          >
            <p className="top">
              <ArrowDiagonalUpRight />
            </p>
            <p className="bottom">Cowrywise×Àṣà IWD: Product Design Course</p>
          </a>
        </div>
      </SectionThreeStyles>
      <HorizontalRuleStyle />

      <Marquee text="Design is One" />

      <HorizontalRuleStyle />
      {/* TODO: scrolling text */}

      <SectionFourStyles>
        <div className="column-1">
          <p className="column-1__heading">Our Forces (4Cs)</p>
          <p className="column-1__brief">Our Forces guide our community and the rest of the inform</p>
        </div>
        <div className="column-2 grid">
          <div className="box box--1"></div>
          <div className="box box--2"></div>
          <div className="box box--3 can-animate">
            <div className="container">
              <div className="top">
                <p className="heading">Craft</p>
                <p className="brief">
                  We believe that craftsmanship should be an ongoing refinement process; approaching a task with
                  religious reverence and intimacy.
                </p>
              </div>
              <div className="bottom">
                <ArrowDiagonalDownRight />
              </div>
            </div>
          </div>
          <div className="box box--4"></div>
          <div className="box box--5"></div>
          <div className="box box--6 can-animate">
            <div className="container">
              <div className="top">
                <p className="heading">Collab-oration</p>
                <p className="brief">We believe that where two or more are gathered, great things can happen.</p>
              </div>
              <div className="bottom">
                <ArrowDiagonalDownRight />
              </div>
            </div>
          </div>
          <div className="box box--7"></div>
          <div className="box box--8"></div>
          <div className="box box--9 can-animate">
            <div className="container">
              <div className="top">
                <p className="heading">Curiosity</p>
                <p className="brief">
                  We believe that child-like curiosity, with the optimism to see it through, is the only true currency
                  in the creative process.
                </p>
              </div>
              <div className="bottom">
                <ArrowDiagonalDownRight />
              </div>
            </div>
          </div>
          <div className="box box--10"></div>
          <div className="box box--11"></div>
          <div className="box box--12"></div>
          <div className="box box--13"></div>
          <div className="box box--14"></div>
          <div className="box box--15 can-animate">
            <div className="container">
              <div className="top">
                <p className="heading">Career</p>
                <p className="brief">
                  We believe that the creative process should be as socio-economically rewarding as it is pivotal to the
                  human condition and about social good.
                </p>
              </div>
              <div className="bottom">
                <ArrowDiagonalDownRight />
              </div>
            </div>
          </div>
          <div className="box box--16"></div>
        </div>
      </SectionFourStyles>
      <HorizontalRuleStyle />
      <SectionFiveStyles>
        <h5 className="heading">Supported by amazing folks</h5>
        <HorizontalRuleStyle />
        <div className="grid">
          {personsGridOne.map((person, index) => (
            <div className="person" key={index}>
              <div className="person__image">
                {person.avatar && <img src={person.avatar} alt={person.name + " image"} className="image" />}
              </div>
              <div className="person__details">
                <p className="person__name">{person.name}</p>
                <p className="person__title">{person.title}</p>
                <p className="person__role">{person.role}</p>
              </div>
              <div className="person__contact">
                <a href={person.twitter} className="twitter" target={"_blank"} rel="noreferrer">
                  <Twitter />
                </a>
                <a href={person.linkedin} className="linkedin">
                  <LinkedIn />
                </a>
              </div>
            </div>
          ))}
        </div>
        <HorizontalRuleStyle />
        <div className="grid-2">
          {personsGridTwo.map((person, index) => (
            <div className="person" key={index}>
              <div className="person__details">
                <p className="person__name">{person.name}</p>
                <p className="person__title">{person.title}</p>
              </div>
              <div className="person__contact">
                <a href={person.twitter} className="" target={"_blank"} rel="noreferrer">
                  <Twitter />
                </a>
                <a href={person.linkedin} className="" target={"_blank"} rel="noreferrer">
                  <LinkedIn />
                </a>
              </div>
            </div>
          ))}
          <div className="support">
            <a
              className="support__link"
              href="mailto:hello@asadsgn.com?subject=Hello%20Asa"
              rel="noreferrer"
              target={"_blank"}
            >
              <div className="support__details">
                <p className="support__heading">Want to Support Us?</p>
                Shoot us an email: hello@asadsgn.com
              </div>

              <ArrowDiagonalDownRight className="support__icon" />
            </a>
          </div>
        </div>
      </SectionFiveStyles>
      <HorizontalRuleStyle />
      <Contact />
      <HorizontalRuleStyle />
      {/* <Footer /> */}
    </div>
  );
}
