import styled, { css, keyframes } from "styled-components";
import { white, black, grey, beige, yellow, green } from "theme/colors";
import * as variables from "theme/variables";
import { responsive } from "theme";

const slide = keyframes`
  0% { margin-top: -87rem}
  5% { margin-top: -72.5rem}
  17% { margin-top: -72.5rem}
  22% { margin-top: -58rem}
  33% { margin-top: -58rem}
  38% { margin-top: -43.5rem}
  50% { margin-top: -43.5rem}
  55% { margin-top: -29rem}
  66% { margin-top: -29rem}
  71% { margin-top: -14.5rem}
  83% { margin-top: -14.5rem}
  88% { margin-top: 0}
  100% { margin-top: 0}
`;

const ContainerStyles = css`
  max-width: 108rem;
  @media only screen and (max-width: 1080px) {
    max-width: 100%;
    padding: 0 3rem;
  }
`;

const headingStyles = css`
  .heading {
    font-family: "Migra";
    font-weight: normal;
    font-size: 4.4rem;
    line-height: 110%;
    color: ${black[100]};
    max-width: 40.3rem;
    ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `}
  }
  .sub-text {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 160%;
    letter-spacing: 0.02em;
    max-width: 40.3rem;
    color: ${black[100]};
  }
`;
export const HorizontalRuleStyle = styled.hr`
  border: 0;
  border-bottom: ${variables.borderStyle};
  margin: unset;
`;

export const SponsorStyles = styled.div`
  .hero {
    ${ContainerStyles}
    margin: 9.9rem auto;
    margin-top: calc(${variables.sidenavWidth} + 9.9rem);
    ${responsive.mobile`
    margin-top: calc(${variables.sidenavWidthSM} + 9.9rem);
    `}
    .btn--primary {
      width: fit-content;
    }
    &__main-text {
      font-family: "Migra";
      font-weight: 100;
      font-size: 12rem;
      line-height: 14.5rem;
      letter-spacing: -0.02em;
      color: ${black[100]};
      display: flex;
      align-items: center;
      .text-md {
        display: none;
        font-weight: 400;
        ${responsive.tabMid`
          display: inline-block
        `}
      }
      ${responsive.tabMid`
        margin-bottom: 2rem;
        font-size: 6.2rem;
        line-height: 118%;
    `}
    }
    &__slider {
      height: 14.5rem;
      padding-left: 3rem;
      overflow: hidden;

      ${responsive.tabMid`
        display: none;
      `}
    }
    &__slider-item {
      font-weight: 400;
      &:first-child {
        animation: ${slide} 10s linear infinite;
      }
    }
    &__sub-text {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 200%;
      color: ${black[100]};
      max-width: 52.5rem;
      margin-bottom: 7.6rem;
    }
  }

  // TRUSTEE

  //DONATE
  .donate {
    margin: 0 auto;
    ${ContainerStyles}
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 72rem;

    ${responsive.tabMid`
      padding: unset;
      grid-template-columns: 1fr;
      grid-template-rows: unset;
    `}
    .card {
      ${headingStyles}
      padding: 3.2rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      .icon {
        position: absolute;
        top: 3.2rem;
        left: 3.2rem;
        transform: translate(-50%, -50%);
      }
      ${responsive.tabMid`
        padding: 2.8rem;

        .heading{
          max-height: unset !important;
        }
        .sub-text{
          margin-bottom: 1.2rem;
        }
        .icon {
        position: absolute;
        top: 2.8rem;
        left: 2.8rem;
        transform: translate(-50%, -50%) scale(0.7);
      }
      `}
      border-left: ${variables.borderStyle};
      &:last-child {
        border-right: ${variables.borderStyle};
      }

      ${responsive.tabMid`
          border-left: unset !important;
            &:not(:last-child) {
              border-bottom: ${variables.borderStyle};
            }
          border-right: ${variables.borderStyle};
      `}
      .img-container {
        width: 100%;
        height: 32rem;
        margin-bottom: 2rem;
        background: ${beige[100]};
        border: ${variables.borderStyle};
        ${responsive.tabMid`
          height: 22rem;
        `}
        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .heading {
        margin-bottom: 8px;
      }
      .sub-text {
        max-width: unset;
        margin-bottom: 1rem;
      }
      .footer {
        margin-top: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.2rem;
        & > :last-child {
          grid-column: 1 / -1;
          grid-row: 2 / 3;
        }

        ${responsive.tabMid`
            grid-template-columns: 1fr;
        `}
      }
      &--1 {
        background: ${yellow[100]};
      }
      &--2 {
        background: ${green[200]};
      }
    }
  }
`;
