import styled, { css, keyframes } from "styled-components";
import { white, black, grey, beige } from "theme/colors";
import * as variables from "theme/variables";
import { responsive } from "theme";
import { borderStyle } from "polished";

export const HorizontalRuleStyle = styled.hr`
  border: 0;
  border-bottom: ${variables.borderStyle};
  margin: unset;
`;

const headingStyles = css`
  .heading {
    font-family: "Migra";
    font-weight: normal;
    font-size: 4.4rem;
    line-height: 110%;
    color: ${black[100]};
    max-width: 43.2rem;
    margin-top: 6.4rem;
    margin-bottom: 1.2rem;
    max-height: ${variables.sidenavWidth};
    ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `}
  }
  .sub-text {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: ${black[100]};
    max-width: 31.2rem;
    margin-bottom: 4.8rem;
  }
`;

const scrollBarHide = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const TestimonialsStyles = styled.div`
  ${headingStyles};
  .heading {
    text-align: center;
    display: block;
    padding: 2rem 0;
    margin: unset;
    max-width: 100%;
    width: 100%;
  }
  .swiper-slide {
    height: auto;
    border-right: 2px solid rgb(28, 22, 22);
  }
  .cards {
    display: flex;
    max-width: calc(100vw - 8rem);
    overflow-x: auto;
    ${scrollBarHide};

    &__item {
      padding: 2.2rem 3.7rem 3.2rem 3.2rem;
      background: ${beige[100]};
      height: 100%;
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        border-right: ${variables.borderStyle};
      }
      .text {
        /* padding-top: 2.2rem; */
        min-height: 30rem;
        font-weight: 200;
        font-size: 2rem;
        line-height: 160%;
        color: ${black[100]};
      }
      .name {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 160%;
        color: ${black[100]};
        display: block;
      }
      .designation {
        font-style: italic;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 160%;
        color: ${black[100]};
        display: block;
      }
    }
    &__footer {
      margin-top: auto;
      display: flex;
      align-items: center;
      & > :not(:last-child) {
        margin-right: 8px;
      }

      img.userImage {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .controls {
    display: flex;
    justify-content: flex-end;
    border-right: ${variables.borderStyle};
    & > :first-child {
      border-left: ${variables.borderStyle};
    }
    & > :not(:last-child) {
      border-right: ${variables.borderStyle};
    }
    button {
      background: unset;
      border: unset;
      padding: 2.8rem 3.2rem;
      cursor: pointer;
    }
  }
`;
