import { createGlobalStyle } from "styled-components";
import { normalize } from "polished";
import { primaryFont, typeScale } from "theme";
import { blue, red, black } from "theme";
import { responsive } from "theme";
import * as variables from "theme/variables";

const GlobalStyles = createGlobalStyle`
    ${normalize()}
    html {
        box-sizing: border-box;
        font-size: 62.5%;
        /* @media only screen and (min-width: 600px) {
            font-size: 25%;
        }
        @media only screen and (min-width: 768px) {
            font-size: 37.5%;
        } */
        /* @media only screen and (max-width: 1200px) {
            font-size: 62.5%;
        } */
        /* @media only screen and (max-width: 992px) {
            font-size: 50%;
        } */

    }
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    *, *:before, *:after {
        box-sizing: border-box;
        /* -webkit-transition: all 0.30s ease-in-out;
        -moz-transition: all 0.30s ease-in-out;
        -ms-transition: all 0.30s ease-in-out;
        -o-transition: all 0.30s ease-in-out; */
    }
    body {
        background: ${({ theme }) => theme.backgroundColor};
        color: ${({ theme }) => theme.textColor};
        transition: all 0.05s linear;
        width: 100%;
        height: 100vh;
        line-height: 2.1rem;
        font-family: ${primaryFont};
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    p {
        font-size: ${typeScale.bodyText1};
        color: ${({ theme }) => theme.textHeader};
        margin: unset;
    }
    h1, h2, h3, h4, h5 {
        font-family: ${primaryFont};
        font-weight: 400;
        color: ${({ theme }) => theme.textHeader};
    }
    h1 {
        margin-bottom: 4.0rem;
        line-height: 4.3rem;
        font-size: ${typeScale.header1};
    }
    h2 {
        margin-bottom: 2.0rem;
        line-height: 2.8rem;
        font-size: ${typeScale.header2};
    }
    h3 {font-size: ${typeScale.header3};}
    h4 {font-size: ${typeScale.header4};}
    h5 {font-size: ${typeScale.header5};}
    header {
        padding: 2rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
            padding: 1rem;
            font-size: ${typeScale.bodyText3};
        }
    }
    a {
        font-family: "Shapiro";
    }
    .btn{
        font-family: "Shapiro";
        padding: 3.4rem 2rem;
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        border: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        cursor: pointer;
        text-decoration: unset;
        max-height: ${variables.sidenavWidth};
        ${responsive.mobile`
            max-height: ${variables.sidenavWidthSM};
        `}
        
        &--primary{
            min-width: 36rem;
            background: ${red[100]};
            ${responsive.mobile`
                min-width: unset;
            `}
        }

        &--secondary{
            min-width: 36rem;
            background: ${blue[100]};
            ${responsive.mobile`
                min-width: unset;
            `}
        }
        &--outline{
            background: unset;
            border: ${variables.borderStyle};
            color: ${black[100]};
        }
    }
    
`;

export default GlobalStyles;
