import React from "react";
import { Helmet } from "react-helmet";
import { ReactComponent as PinkFlower } from "assets/images/svg/flower-pink.svg";
import { ReactComponent as BlueFlower } from "assets/images/svg/flower-blue.svg";
import { ReactComponent as ComingSoonPurple } from "assets/images/svg/coming-soon-purple.svg";
import { ReactComponent as ComingSoonOrange } from "assets/images/svg/coming-soon-orange.svg";
import { ReactComponent as ComingSoonGreen } from "assets/images/svg/coming-soon-green.svg";
import { ReactComponent as DoubleCaretRight } from "assets/icons/svg/double-caret-right.svg";
import {
  HeroStyles,
  HorizontalRuleStyle,
  DescriptionsStyle,
  DescriptionsContainerStyle,
  TrusteesStyle,
  ProjectsStyles
} from "./home.styles";
import Testimonials from "components/testimonials/testimonials.component";
import Contact from "components/contact/contact.component";
import Trustee from "components/trustee/trustee.component";
import Marquee from "components/marquee/marquee.component";
import Gallery from "components/gallery/gallery.component";
import { withRouter } from "react-router-dom";

function Home(props) {
  const { history } = props;
  return (
    <div className="">
      {/* HERO */}
      <HeroStyles className="hero">
        <div className="hero__row">
          <p className="transcription">/əˈʃə/</p>
          <p className="brief">
            Asa design is a philosophy and approach to design that encourages a careful obsession with and a deep faith
            in the social and business benefit of an informed execution of good design - beautiful, functional and
            ethical.
          </p>
        </div>

        <p className="transcription transcription--sm">/əˈʃə/</p>
        <h4 className="hero__main-text">
          <span>The Àṣà Experiment</span>
          <PinkFlower className="pink" />
          <BlueFlower className="blue" />
        </h4>
        <p className="brief brief--sm">
          Asa design is a philosophy and approach to design that encourages a careful obsession with and a deep faith in
          the social and business benefit of an informed execution of good design - beautiful, functional and ethical.
        </p>
        <button className="btn btn--primary" onClick={() => history.push("/sponsor")}>
          support our initiatives
        </button>
      </HeroStyles>
      <HorizontalRuleStyle />
      {/* SOCIALS */}

      {/* BRIEFS */}
      <DescriptionsContainerStyle>
        <DescriptionsStyle>
          <div className="description__card">
            <p className="title">Àṣà coterie</p>
            <div className="text">
              Àṣà Coterie is a cross-disciplinary design community that enables conversation and collaboration amongst
              designers to establish a culture of good design in Nigeria and Africa.
            </div>
            <button className="btn btn--desc learn-more">
              <span>Learn more</span>
              <DoubleCaretRight />
              <ComingSoonPurple className="icon" />
            </button>
          </div>
          <div className="description__card">
            <div className="title">Àṣà education</div>
            <div className="text">
              A philosophy founded on the culture of lifelong learning and growth being imbibed in designers with an
              atmosphere of depth and intense learning.
            </div>
            <button className="btn btn--desc learn-more">
              <span>Learn more</span>
              <DoubleCaretRight />
              <ComingSoonOrange className="icon" />
            </button>
          </div>
          <div className="description__card">
            <div className="title">mbarihaus</div>
            <div className="text">
              A smaller experiment within the Àșà experiment, Mbari is an ancient igbo tradition where people are
              dedicated from birth to spend the rest of their lives creating works of arts and craft, to advance
              society, to venerate deities, or to journal the zeitgeist of that time period.
            </div>
            <button className="btn btn--desc learn-more">
              <span>Learn more</span>
              <DoubleCaretRight />
              <ComingSoonGreen className="icon" />
            </button>
          </div>
        </DescriptionsStyle>
      </DescriptionsContainerStyle>
      <HorizontalRuleStyle />
      <Gallery hideHeroImage={true} />
      <Marquee text="Design is one" />
      <HorizontalRuleStyle />
      {/* TRUSTEES */}
      <Trustee />
      <Marquee text="supporting design" />
      <HorizontalRuleStyle />
      {/* PROJECTS */}
      <ProjectsStyles>
        <div className="first-column">
          <h5 className="heading">Featured projects</h5>
          <p className="sub-text">
            We are constantly working on projects that embody the values and ethics of the Àṣà culture.
          </p>
          <button className="btn btn--primary" onClick={() => history.push("/sponsor")}>
            Fund a project
          </button>
        </div>
        <div className="second-column">
          <a className="project" href="https://cowrywise.com/blog/women-in-design/" target={"_blank"} rel="noreferrer">
            <p className="project__heading">Cowrywise×Àṣà: Investing in Female Designers</p>
            <p className="project__brief">We partnered to deliver product design training to 25 women.</p>
            <p className="project__stat"> 2021 . Completed . Training</p>
            <img
              src={require("../../../../assets/images/png/cowry-asa-1.5x.png").default}
              alt="persons image"
              srcSet={`${require("../../../../assets/images/png/cowry-asa-1.5x.png").default} 1.5x, ${
                require("../../../../assets/images/png/cowry-asa-3x.png").default
              } 3x`}
              className="project__image"
            />
          </a>
          <a className="project" href="https://sodbyasa.webflow.io/report" target={"_blank"} rel="noreferrer">
            <p className="project__heading">Stateof.Design: Understanding the Ecosystems</p>
            <p className="project__brief">We are running surverys to get a pulse on the adoption rate of design.</p>
            <p className="project__stat"> 2020 – 2021 . ongoing . research</p>
            <img
              src={require("../../../../assets/images/png/state-of-design-1.5x.png").default}
              alt="persons image"
              srcSet={`${require("../../../../assets/images/png/state-of-design-1.5x.png").default} 1.5x, ${
                require("../../../../assets/images/png/state-of-design-3x.png").default
              } 3x`}
              className="project__image"
            />
          </a>
          <a
            className="project"
            href="https://www.youtube.com/playlist?list=PLIN9NLuy_J9KtvgdMY_rP0J13YSjZ_0jF"
            target={"_blank"}
            rel="noreferrer"
          >
            <p className="project__heading">Àṣà Sessions: Curating Learning with Designers</p>
            <p className="project__brief">
              An education project curated and hosted by the Àșà Coterie, featuring designers who share knowledge,
              methods and useful tips.
            </p>
            <p className="project__stat"> 2021 . ongoing . documentary</p>
            <img
              src={require("../../../../assets/images/png/asa-sessions-1.5x.png").default}
              alt="persons image"
              srcSet={`${require("../../../../assets/images/png/asa-sessions-1.5x.png").default} 1.5x, ${
                require("../../../../assets/images/png/asa-sessions-3x.png").default
              } 3x`}
              className="project__image"
            />
          </a>
        </div>
      </ProjectsStyles>
      <HorizontalRuleStyle />
      {/* TESTMONIALS */}
      <Testimonials />
      {/* CONTACT */}
      <Contact />
      <HorizontalRuleStyle />
    </div>
  );
}
export default withRouter(Home);
