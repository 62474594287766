import { useField } from "formik";
import React from "react";
import { red } from "theme/colors";

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-field">
      <label htmlFor="email" className="form__label">
        {`${label}`}
      </label>
      <input {...field} {...props} />
      {meta.touched && meta.error ? <em style={{ fontSize: "1.2rem", color: `${red[100]}` }}>{meta.error}</em> : null}
    </div>
  );
};
export default TextInput;
