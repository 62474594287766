import React from "react";
import { GalleryStyles } from "./gallery.styles";
import { ReactComponent as HeroImg } from "assets/images/svg/sponsor-hero.svg";
import { ReactComponent as PinkFlower } from "assets/images/svg/flower-pink.svg";
import { ReactComponent as AsaStarOrange } from "assets/images/svg/asa-star-orange.svg";

export default function Gallery(props) {
  const { hideHeroImage } = props;
  return (
    <GalleryStyles className="gallery">
      <PinkFlower className="pink" />
      <AsaStarOrange className="logo" />
      {!hideHeroImage && <HeroImg className="heroImg" />}
      <div className="gallery__item gallery__item--1">
        <img
          src={require("../../assets/images/png/diverse03-1.5x.png").default}
          alt="persons image"
          srcSet={`${require("../../assets/images/png/diverse03-1.5x.png").default} 1.5x, ${
            require("../../assets/images/png/diverse03-3x.png").default
          } 3x`}
          className="image"
        />
      </div>
      <div className="gallery__item gallery__item--2">
        <img
          src={require("../../assets/images/png/diverse02-1.5x.png").default}
          srcSet={`${require("../../assets/images/png/diverse02-1.5x.png").default} 1.5x, ${
            require("../../assets/images/png/diverse02-3x.png").default
          } 3x`}
          alt="person image"
          className="image"
        />
      </div>
      <div className="gallery__item gallery__item--3">
        <img
          src={require("../../assets/images/png/diverse01-1.5x.png").default}
          alt="person image"
          srcSet={`${require("../../assets/images/png/diverse01-1.5x.png").default} 1.5x, ${
            require("../../assets/images/png/diverse01-3x.png").default
          } 3x`}
          className="image"
        />
      </div>
      <div className="gallery__item gallery__item--4">
        <img
          src={require("../../assets/images/png/person01-1.5x.png").default}
          alt="person image"
          srcSet={`${require("../../assets/images/png/person01-1.5x.png").default} 1.5x, ${
            require("../../assets/images/png/person01-3x.png").default
          } 3x`}
          className="image"
        />
      </div>
      <div className="gallery__item gallery__item--5">
        <img
          src={require("../../assets/images/png/person02-1.5x.png").default}
          alt="person image"
          srcSet={`${require("../../assets/images/png/person02-1.5x.png").default} 1.5x, ${
            require("../../assets/images/png/person02-3x.png").default
          } 3x`}
          className="image"
        />
      </div>
    </GalleryStyles>
  );
}
