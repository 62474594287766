import styled, { css, keyframes } from "styled-components";
import { white, black, grey, beige } from "theme/colors";
import * as variables from "theme/variables";
import { responsive } from "theme";

const headingStyles = css`
  .heading {
    font-family: "Migra";
    font-weight: normal;
    font-size: 4.4rem;
    line-height: 110%;
    color: ${black[100]};
    max-width: 43.2rem;
    margin-top: 6.4rem;
    margin-bottom: 1.2rem;
    max-height: ${variables.sidenavWidth};
    ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `}
  }
  .sub-text {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: ${black[100]};
    max-width: 31.2rem;
    margin-bottom: 4.8rem;
  }
`;

const ContainerStyles = css`
  max-width: 108rem;
  @media only screen and (max-width: 1080px) {
    max-width: 100%;
    padding: 0 3rem;
  }
`;

export const HeroStyles = styled.div`
  ${ContainerStyles}
  margin: 9.9rem auto;
  margin-top: calc(${variables.sidenavWidth} + 9.9rem);
  ${responsive.mobile`
    margin-top: calc(${variables.sidenavWidthSM} + 9.9rem);
  `}

  .hero__row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 4rem;
  }
  .transcription {
    font-weight: 200;
    font-size: 4rem;
    line-height: 4.8rem;
    letter-spacing: -0.04em;
    color: ${black[100]};
    &--sm {
      display: none;
    }
    ${responsive.tabMid`
        display: none;
        &--sm{
            display: flex;
            margin-bottom: 3.2rem;
            font-size: 2.0rem;
        }
    `}
  }
  .brief {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 190%;
    letter-spacing: 0.02em;
    max-width: 25.6rem;
    color: ${black[100]};
    &--sm {
      display: none;
    }
    ${responsive.tabMid`
        display: none;
        &--sm{
            display: block;
            font-weight: normal;
            margin-bottom: 4.4rem;
            font-size: 2.0rem;
            max-width: 100%;
        }
    `}
  }

  .hero__main-text {
    font-family: "Migra";
    font-weight: 200;
    font-size: 15.2rem;
    line-height: 18.4rem;
    letter-spacing: -0.035em;
    margin: unset;
    margin-bottom: 4.7rem;
    color: ${black[100]};
    position: relative;
    .pink {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;

      ${responsive.tabMid`
      display: none;
    `}
    }
    .blue {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(40%, -40%);
      z-index: 1;

      ${responsive.tabMid`
      display: none;
    `}
    }
    ${responsive.tabMid`
        margin-bottom: 2rem;
        font-size: 6.2rem;
        line-height: 90%;
    `}
  }

  .btn {
    display: flex;
    margin-left: auto;
    ${responsive.tabMid`
        margin-right: -3rem;
    `}
  }
`;
export const HorizontalRuleStyle = styled.hr`
  border: 0;
  border-bottom: ${variables.borderStyle};
  margin: unset;
`;
export const SocialsStyle = styled.div`
  bottom: 0;
  background: #fff;
  z-index: 1;
  height: ${variables.sidenavWidth};
  align-items: center;
  width: calc(100vw - ${variables.sidenavWidth});
  ${responsive.mobile`
    width: calc(100vw - ${variables.sidenavWidthSM});
  `}
  display: flex;
  align-items: center;
  .div {
    margin: 0 auto;
    max-width: 108rem;
    width: 100%;
    & > :not(:last-child) {
      margin-right: 5.2rem;
    }
  }
`;
export const DescriptionsContainerStyle = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: ${variables.borderStyle};
    bottom: 8rem;

    ${responsive.tabMid`
        content: unset;
    `}
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: ${variables.borderStyle};
    top: 6.4rem;
    ${responsive.tabMid`
        content: unset;
    `}
  }
`;
export const DescriptionsStyle = styled.div`
  ${ContainerStyles}
  margin: 0 auto;
  display: flex;
  border-left: ${variables.borderStyle};
  border-right: ${variables.borderStyle};
  ${responsive.tabPortrait`
        padding: unset;
    `}
  ${responsive.tabMid`
        display: block;
        border-left: none;
        border-right: none;
    `}
  .description__card:not(:last-child) {
    border-right: ${variables.borderStyle};
    ${responsive.tabMid`
        border-right: unset;
    `}
  }
  .description__card:first-child p {
    ${responsive.tabMid`
        border-top: unset !important;
    `}
  }
  .description__card {
    flex: 1;
    &:last-child {
      ${responsive.tabMid`
        .btn--desc{
            border-bottom: unset;
        }
    `}
    }
  }
  .title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    padding: 2.8rem 3rem 1rem 3rem;
    /* display: none; */
    color: ${black[100]};
    ${responsive.tabMid`
        border-bottom: ${variables.borderStyle};
        display: block;
        border-top: ${variables.borderStyle};
    `}
  }
  .text {
    padding: 1.6rem 3rem;
    min-height: 40rem;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: ${black[100]};
  }
  .btn--desc {
    /* display: none !important; */
    width: 100%;
    padding: 3.2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${beige[100]};
    min-width: unset;
    max-height: ${variables.sidenavWidth};
    ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `}
    & > :not(:last-child) {
      font-size: 1.2rem;
      line-height: 1.8rem;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: ${black[100]};
      margin-right: 0.73rem;
    }

    ${responsive.tabMid`
        border-top: ${variables.borderStyle};
    `}
  }
  .learn-more {
    position: relative;

    .icon {
      position: absolute;
      display: none;
      /* opacity: 0; */
    }

    &:hover .icon {
      display: block;
      animation: bounceInUp;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
    }
  }
`;
export const TrusteesStyle = styled.div`
  ${ContainerStyles}
  margin: 0 auto;
  display: flex;
  ${responsive.tabMid`
  padding: 5rem 0;
  padding-right: unset;
  padding-bottom: unset;
    flex-direction: column;
`}
  & > * {
    flex: 1;
  }

  .first-column {
    ${headingStyles}
    ${responsive.tabMid`
        padding: 0 2rem;
        .heading{
          max-height: unset;
        }
    `}
  }
  .second-column {
    ${responsive.tabMid`
    margin-top: 5rem;
`}
    .card-row {
      display: flex;
      &:nth-child(1) {
        border-left: ${variables.borderStyle};
        ${responsive.tabMid`
            border: unset;
            border-top: ${variables.borderStyle};
            & >:not(:last-child){
                border-right: ${variables.borderStyle};
            }
        `}
        & > * {
          border-right: ${variables.borderStyle};
          border-bottom: ${variables.borderStyle};
          ${responsive.tabMid`
            border-right: unset;
            width: 50%;
        `}
        }
      }
      &:nth-child(2) {
        border-left: ${variables.borderStyle};
        & > * {
          border-right: ${variables.borderStyle};
          ${responsive.tabMid`
            border-right: unset;
            flex: 1;
        `}
        }
        ${responsive.tabMid`
            border: unset;
            & >:not(:last-child){
                border-right: ${variables.borderStyle};
            }
        `}
      }
    }
    .trustee {
      min-width: 27rem;
      min-height: 27rem;
      display: flex;
      align-items: center;
      justify-content: center;
      ${responsive.tabMid`
        & >*{
            transform: scale(0.5);
        }
        min-width: unset;
        min-height: unset;
        padding: 2rem 0;
        overflow: hidden;
    `}
    }
  }
`;
export const ProjectsStyles = styled.div`
  ${ContainerStyles}
  margin: 6.4rem auto;
  margin-bottom: 16rem;
  display: flex;
  ${responsive.tabMid`
  padding: 5rem 2rem;
  padding-right: unset;
    flex-direction: column;
`}
  & > * {
    flex: 1;
  }
  .first-column {
    display: flex;
    flex-direction: column;
    ${headingStyles}

    .heading {
      margin-top: unset;
    }
    .btn {
      width: fit-content;
      ${responsive.tabMid`
        display: none;
    `}
    }
  }
  .second-column {
    ${responsive.tabMid`
        margin-top: 4.8rem;
    `}
    .project {
      cursor: pointer;
      position: relative;
      text-decoration: unset;
      font-family: "Inter";
      display: block;
      &__image {
        position: absolute;
        top: -50%;
        right: 0;
        transform: translateY(-100%);
        z-index: 20;
        display: none;
        width: 25.2rem;
        height: auto;
      }

      &:hover .project__image {
        display: block;
        animation: bounceInRight;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
      }
      &:not(:last-child) {
        .project__stat {
          padding-bottom: 2.4rem;
        }
        border-bottom: ${variables.borderStyle};
        border-width: 1px;
        margin-bottom: 2.4rem;
      }
      &__heading {
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 2.7rem;
        margin-bottom: 0.4rem;
        color: ${black[100]};
      }
      &__brief {
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 160%;
        letter-spacing: 0.02em;
        margin-bottom: 1.6rem;
        color: ${black[100]};
      }
      &__stat {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: ${grey[100]};
      }
    }
  }
`;
