import React from "react";
import { ReactComponent as Cowrywise } from "assets/icons/svg/cowry-wise-logo.svg";
import { ReactComponent as Semicolon } from "assets/icons/svg/semi-colon-logo.svg";
import { ReactComponent as Printivo } from "assets/icons/svg/printivo-logo.svg";
import { ReactComponent as Propel } from "assets/icons/svg/propel-logo.svg";
import { TrusteeStyles, HorizontalRuleStyle } from "./trustee.styles";

export default function Trustee() {
  return (
    <TrusteeStyles className="trustee">
      <div className="row">
        <h5 className="heading">Trusted by design-first companies</h5>
        <p className="sub-text">
          We are trusted by companies who understand and believe in the impact of design and its value in people&apos;s
          lives.
        </p>
      </div>
      <HorizontalRuleStyle />
      <div className="trustee__container">
        <div className="first-row">
          <div className="trustee__img trustee__img--1">
            <Cowrywise />
          </div>
          <div className="trustee__img trustee__img--2">
            <Semicolon />
          </div>
          <div className="trustee__img trustee__img--3">
            <Printivo />
          </div>
          <div className="trustee__img trustee__img--4">
            <Propel />
          </div>
        </div>
        <div className="second-row">
          <div className="trustee__img trustee__img--5">
            <Printivo />
          </div>
          <div className="trustee__img trustee__img--6">
            <Propel />
          </div>
          <div className="trustee__img trustee__img--7">
            <Cowrywise />
          </div>
          <div className="trustee__img trustee__img--8">
            <Semicolon />
          </div>
          <div className="trustee__img trustee__img--9">
            <Semicolon />
          </div>
        </div>
      </div>
    </TrusteeStyles>
  );
}
