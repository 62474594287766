import React from "react";
import { Scrolling } from "./marquee.styles";
import { ReactComponent as Star } from "assets/icons/svg/star.svg";
export default function Marquee(props) {
  return (
    <Scrolling>
      <p className="text">
        {new Array(15).fill(3).map((val, index) => (
          <span className="chunk" key={index}>
            <span>{props.text}</span>
            <Star />
          </span>
        ))}
      </p>
    </Scrolling>
  );
}
