import React, { Suspense, useEffect, useRef } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/Loading";
import { defaultRoute, publicRoutes } from "routes/routes-list";
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Sponsor from "./pages/sponsor/sponsor";
import { useLocation } from "react-router-dom";
import Footer from "components/footer/footer.component";
import Header from "components/header/header.component";
import SideNav from "components/sideNav/sideNav.component";
import styled from "styled-components";
import * as variables from "../../theme/variables";
import { responsive } from "theme";
import ScrollToTop from "components/scrollToTop/scrollToTop";
// const Home = React.lazy(() => import("./pages/home/home"));

const MainContainerStyles = styled.div`
  max-height: 100vh;
  overflow: auto;
  .side-nav {
    /* width: 9rem; */
    height: 100%;
    position: fixed;
    top: 0;
    width: ${variables.sidenavWidth};
    border-right: ${variables.borderStyle};
    z-index: 100;
    ${responsive.mobile`
  width: ${variables.sidenavWidthSM};
`}
  }
  .content {
    width: calc(100vw - ${variables.sidenavWidth});
    margin-left: ${variables.sidenavWidth};
    height: 100vh;
    ${responsive.mobile`
  width: calc(100vw - ${variables.sidenavWidthSM});
  margin-left: ${variables.sidenavWidthSM};
`}
  }
`;

const Website = () => {
  const container = useRef(null);
  const firstFooter = useRef(null);
  const secondFooter = useRef(null);
  let throttleTimer = false;

  useEffect(() => {
    container.current.onscroll = throttle.bind(null, handleScrollAnimation, 250);
  }, []);

  const throttle = (callback, time) => {
    if (throttleTimer) return;

    throttleTimer = true;

    setTimeout(() => {
      callback();
      throttleTimer = false;
    }, time);
  };

  function elementAtBottom() {
    // eslint-disable-next-line no-console
    const elementAtBottom = this.scrollHeight - Math.round(this.scrollTop) <= this.clientHeight + 600;
    return elementAtBottom;
  }

  const handleScrollAnimation = () => {
    // eslint-disable-next-line no-console
    if (elementAtBottom.call(container.current)) {
      firstFooter.current?.classList.remove("show");
      secondFooter.current?.classList.add("show");
    } else {
      secondFooter.current?.classList.remove("show");
      if (!firstFooter.current?.classList.contains("show")) {
        firstFooter.current?.classList.add("show");
      }
    }
  };

  return (
    <MainContainerStyles>
      <div className="side-nav">
        <SideNav />
      </div>
      <div className="content" style={{ maxHeight: "100%", overflow: "auto" }} ref={container}>
        <Header />
        <Suspense fallback={<Loading />}>
          <ScrollToTop container={container}>
            <Switch>
              <Route path={defaultRoute} exact component={Home} />
              <Route path={publicRoutes.about} exact component={About} />
              <Route path={publicRoutes.sponsor} exact component={Sponsor} />
            </Switch>
          </ScrollToTop>
        </Suspense>
        <Footer
          ref={{
            firstFooterRef: firstFooter,
            secondFooterRef: secondFooter
          }}
        />
      </div>
    </MainContainerStyles>
  );
};

export default Website;
