export const defaultRoute = "/";

export const authRoutes = {
  signIn: "/sign-in"
};

export const publicRoutes = {
  about: "/about",
  sponsor: "/sponsor"
};

export const privateRoutes = {
  test: "/test"
};
