import React from "react";
import { ReactComponent as Logo } from "assets/icons/svg/logo.svg";
import { ReactComponent as Arrow } from "assets/icons/svg/arrow-right.svg";
import { ReactComponent as ComingSoonPurple } from "assets/images/svg/coming-soon-purple.svg";
import { ReactComponent as ComingSoonOrange } from "assets/images/svg/coming-soon-orange.svg";
import { ReactComponent as ComingSoonGreen } from "assets/images/svg/coming-soon-green.svg";
import styled from "styled-components";
import { black } from "../../theme/colors";
import * as variables from "../../theme/variables";
import { responsive } from "theme";
import { Link } from "react-router-dom";
export default function SideNav() {
  return (
    <SideNavStyles>
      <ul className="list">
        <Link className="logo" to="/">
          <Logo />
        </Link>
        <li className="list__item">
          <a href="" className="list__link">
            asa coterie
          </a>
          <ComingSoonPurple className="icon" />
        </li>
        <li className="list__item">
          <a href="" className="list__link">
            asa education
          </a>
          <ComingSoonOrange className="icon" />
        </li>
        <li className="list__item">
          <a href="" className="list__link">
            mbarihaus
          </a>
          <ComingSoonGreen className="icon" />
        </li>
        <li className="arrow" onClick={window.scrollTo(0, document.body.scrollHeight)}>
          <Arrow />
        </li>
      </ul>
    </SideNavStyles>
  );
}

const SideNavStyles = styled.aside`
  height: 100%;
  .list {
    margin-top: unset;
    height: 100%;
    padding-left: unset;
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  .list__item {
    border-bottom: ${variables.borderStyle};
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 20;
      display: none;
      /* opacity: 0; */
    }

    &:hover .icon {
      display: block;
      animation: bounceInLeft;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
    }
  }
  .list__link {
    writing-mode: vertical-rl;
    font-size: 1.2rem;
    line-height: 1.8rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 3.4rem 2.2rem;
    display: inline-block;
    color: ${black[100]};
    text-decoration: none;
  }
  .logo,
  .arrow {
    display: flex;
    align-items: center;
    padding: 2rem;
    border-bottom: ${variables.borderStyle};
    height: ${variables.sidenavWidth};
    ${responsive.mobile`
      height: ${variables.sidenavWidthSM};
    `}
  }
  .arrow {
    border-bottom: unset;
    transform: rotate(90deg);
  }
`;
