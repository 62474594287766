import React from "react";
import { TestimonialsStyles, HorizontalRuleStyle } from "./testimonials.styles";
import { ReactComponent as ArrowLeft } from "assets/icons/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/svg/arrow-right.svg";
import SwiperSlider from "components/swiper/swiper.component";

export default function Testimonials() {
  return (
    <TestimonialsStyles>
      <h5 className="heading">Testimonials</h5>
      <HorizontalRuleStyle />
      <SwiperSlider>
        <HorizontalRuleStyle />
        <div className="controls">
          <button className="btn--left swiper-prev">
            <ArrowLeft />
          </button>
          <button className="btn--left swiper-next">
            <ArrowRight />
          </button>
        </div>
      </SwiperSlider>
      <HorizontalRuleStyle />
    </TestimonialsStyles>
  );
}
