import styled, { css, keyframes } from "styled-components";

import * as variables from "theme/variables";
import { white, black, grey, beige, yellow, green } from "theme/colors";
import { responsive } from "theme";

const ContainerStyles = css`
  max-width: 108rem;
  @media only screen and (max-width: 1080px) {
    max-width: 100%;
    padding: 0 3rem;
  }
`;

const headingStyles = css`
  .heading {
    font-family: "Migra";
    font-weight: normal;
    font-size: 4.4rem;
    line-height: 110%;
    color: ${black[100]};
    max-width: 40.3rem;
    /* ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `} */
  }
  .sub-text {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 160%;
    letter-spacing: 0.02em;
    max-width: 40.3rem;
    color: ${black[100]};
  }
`;

export const HorizontalRuleStyle = styled.hr`
  border: 0;
  border-bottom: ${variables.borderStyle};
  margin: unset;
`;

export const TrusteeStyles = styled.div`
  // TRUSTEE
  &.trustee {
    margin-top: 6.4rem;
    ${responsive.tabMid`
          margin-top: unset;
        `}

    .row {
      margin: 0 auto;
      ${ContainerStyles}
      ${headingStyles}
    
        width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 2.9rem;
      .sub-text {
        margin-left: auto;
      }

      ${responsive.tabMid`
          flex-direction: column;
          align-items: flex-start;
          .sub-text{
            margin-left: unset;
          }
        `}
    }
    .trustee__container {
      .first-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 9.6rem;
        & > :not(:last-child) {
          border-right: ${variables.borderStyle};
        }
        border-bottom: ${variables.borderStyle};

        ${responsive.tabMid`
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: unset;
                & > *{
                  border-bottom: ${variables.borderStyle};
              border-right: ${variables.borderStyle};
                }
            `}

        ${responsive.mobile`
                grid-template-columns: 1fr;
            `}
      }
      .second-row {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 9.6rem;
        ${responsive.tabMid`
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: unset;
                & > *{
                  border-bottom: ${variables.borderStyle};
              border-right: ${variables.borderStyle};
                }
                &:last-child{
                  border-bottom: unset;
                }
            `}

        ${responsive.mobile`
                grid-template-columns: 1fr;
            `}
            & > :not(:last-child) {
          border-right: ${variables.borderStyle};
        }
        border-bottom: ${variables.borderStyle};
      }
    }
    .trustee__img {
      display: flex;
      align-items: center;
      justify-content: center;
      ${responsive.tabMid`
              padding: 2rem;
              svg {
                transform: scale(0.7);
              }
          `}
    }
  }
`;
