export const testimonials = [
  {
    comment: `The community has been a safe space. I love how we’re able to talk about anything and everything. I don’t live in Nigeria so I sometimes miss the country & people, Asa has helped in filling that gap for me.`,
    name: "Dami Oludumila",
    role: "UX Researcher",
    image: "https://lh3.googleusercontent.com/CLpkYm8RcK3yKzuCLvPw7j8cBCuTM3yU5EBN_DKLvuPFOsF3Xraf_e7CdUiP2LbW5LE=w2400"
  },
  {
    comment: `I have been able to meet like minds. And people who I have changed my perception on a lot of things`,
    name: "Olamide Rowland",
    role: "Motion designer",
    image: "https://lh5.googleusercontent.com/Q3MEElokTHdMqXJN_2DBxFoJJqkhvAWYqAUaHJel5NHqUCSMnIr6JhohZ4CLXMUFRCI=w2400"
  },
  {
    comment: `Hospitality aside, there are a lot of intelligent people on there who've helped me grow in this skill. I literally learn something new everyday. I'm convinced that I wouldn't know as much as I currently do if I was never added to the group.`,
    name: "Aderinsola Oluwafemi",
    role: "Product designer",
    image: "https://lh4.googleusercontent.com/y8o2kfJcjwH7iY8GCpPANoHQDrTkoVu1B-Gefi_JppMNyeD9isin93rDbUqQFP-d1xw=w2400"
  },
  {
    comment: `The community has been welcoming. It has streched me positively. It showed me that's okay to show creativity, it's okay to be a learner. I know this is all vague but the community has improved my mindset on human centred designs.`,
    name: "John Wright-Nyingifa",
    role: "Blockchain designer",
    image: "https://lh5.googleusercontent.com/mhhzpH3hvEe7JjXOg62PusZbXJAHHTVsbGjhmCgJlXddNHaE0qg5r6bsTEkIdONON2w=w2400"
  },
  {
    comment: `Basically, learning, been able to give back in form of experience and knowledge and friendship are the values I have received as a member.`,
    name: "Chukwuka Ezeoke",
    role: "Product designer",
    image: "https://lh6.googleusercontent.com/mAMMImkuVvkloVZwSwoFTLOQkvWLoVpLXTK_uIG_AwKIJr6vEGLyHa55uO9eR-QchQg=w2400"
  },
  {
    comment: `The wealth of talent it has and I’ve gotten tons of resources.`,
    name: "Mayowa Adeyemi",
    role: "UX Writer",
    image: "https://lh6.googleusercontent.com/E7Eb2AcpE574pqw2w6J4lpnNRb_qxmS1zfQ_etpuvsJYbyheajLwt8xj8MNN7o8sFdc=w2400"
  },
  {
    comment: `Thank you Caleb for the platform. Learnt a lot from the group, you, Mudia. 
    Lol, "label your icons". If anything, I've seen you guys put so much effort to achieve results, attention to the tiniest details. No place for mediocrity.
     Do it well or don't do it at all.    
      `,
    name: "Aanuoluwapo Sebiomo",
    role: "Product designer",
    image: "https://lh4.googleusercontent.com/aXl4fV_B_3GU3jzqmA_sk_xOLZHxGqeXA4yg_tSjU3q1k7PKAn1TnGGKa6R2O7UCtHQ=w2400"
  },
  {
    comment: `The Asa Sessions have been a great learning experience, especially the brand aspects for me cos of my niche. Asa strives to build a community for creatives in a way that is more organized and meaningful than I may have come across anywhere else.`,
    name: "Francis Essel Etetere",
    role: "Brand designer",
    image: "https://lh5.googleusercontent.com/PjlnENr1VTvAYNVj9ZMKucZXCZE5yVUD3qnqz9Orbye2jeRocXpI1GGEDsnLewMwdOk=w2400"
  },
  {
    comment: `Reading a bunch of cool articles and Interacting with a lot of awesome designers`,
    name: "Jemima Oluwadarasimi Assim-ita",
    role: "Product designer",
    image: "https://lh6.googleusercontent.com/q4PWhnCLmIZ8vGR9M3Z33uY-SOgAJJs0dIP_sR3TBFD0wOePQaFLqlM_e4p_KMytFFQ=w2400"
  },
  {
    comment: `I love the energy and support`,
    name: "Seun Badejo",
    role: "Brand designer",
    image: "https://lh3.googleusercontent.com/n84BO5vRDNHBGTS9zyQf6L3FzOVPBjo3ekStybwUXW__opcQF_ELK-_82fwf5nvhU3o=w2400"
  },
  {
    comment: `The community has been of immense help. I don't think I would have continued along my design career path without the coterie.
    Amazing friends, great insight and the much needed push to keep creating are just a few.
   `,
    name: "Osaz Ehiabhi",
    role: "Product designer",
    image: "https://lh3.googleusercontent.com/lNXI7wo2c565_PGZrjqW0ySsvgztU8LcKxtJ1fbRclZ_j4FdLJFGEOTsDIQdJ8pRw3Y=w2400"
  },
  {
    comment: `Understanding the concept of creating designs that are beyond aesthetics, Insight into the daily struggles of designers and a culture with emphasis on the importance of quality, creativity, ethics and ingenuity with regards to design.     
      `,
    name: "Oluwabusayomi Sunmonu",
    role: "Product designer",
    image: "https://lh3.googleusercontent.com/ms5YiW68dynwFlwtWaLy7kuG-DkXCSRyO8w7KmtKU4Ks0k6uFrB8nejw3D20LaRgcAg=w2400"
  },
  {
    comment: `Being in the midst of people with skillset I wouldn't normally have access to and learning a lot from conversations from debates, not to mention resources, resources, resources.`,
    name: "Daniel Abudu",
    role: "Product designer",
    image: "https://lh3.googleusercontent.com/ld6W3kALuQKe67Ir1dMXTrD6u6dOyGhqvVkV_IVMplM-2ZMBle7mjjGFPuF8pZDP2xk=w2400"
  },
  {
    comment: `It has helped me grow as a designer understanding the principles that guide design and best ways to apply them.
      `,
    name: "Kanyinsola Taiwo",
    role: "Product designer",
    image: "https://lh3.googleusercontent.com/afWcOZzlVsVJcSP67gDTwiGbpWYDBYH-6iZqesDRKVUDUSHlbSOaq2T6Ts9raBCNraA=w2400"
  },
  {
    comment: `Even as an outsider the Group has been of help with online sessions they hold and with members dishing out advice on twitter
      `,
    name: " Ibukunoluwa Adesanya",
    role: "Creative designer",
    image: "https://lh5.googleusercontent.com/zf0UkW9SYYY_jE07fksq5Vlobs98Mf2vTfZ7cenFTOIp0wyihEj7KYHjgYDA4q5M8X0=w2400"
  },
  {
    comment: `At various points, Emotionally, and generally all round growth in design, also broadening my scope of ideas and resources.
      `,
    name: "Kelechi Nwuke",
    role: "Graphic designer",
    image: "https://lh3.googleusercontent.com/cws__2nMNIY0ChL3bvCLoZxoVRnptLDeVYhQh-iwa35tTLCALrYiTXrq--s50C2ndvM=w2400"
  },
  {
    comment: `Collaboration, recognition, advise—hella a lot
      `,
    name: "Temitayo Alamu",
    role: "Brand designer",
    image: "https://lh6.googleusercontent.com/MsCRw34WCxZTQ5KPtjl2nJMyNW65vvOAkjtjrY7JpyyhvR89m-rtihk3MycD7afKX38=w2400"
  },
  {
    comment: `The knowledge sharing here has been amazing. I’ve learned a lot.`,
    name: "Ifeoluwa Aminu",
    role: "Visual designer",
    image: "https://lh5.googleusercontent.com/zTQulmI0alMbqU5LnxblB5nZQfQzpS_ETSf78LTjkBJQ8QGLkS6fhrKIIlgTxNjQG4k=w2400"
  },
  {
    comment: `A sense of support and togetherness. There's always someone to encourage you and make you feel better about your work.`,
    name: "Latade Igbodipe",
    role: "Product designer",
    image: "https://lh4.googleusercontent.com/sAjaF5on3UHJppbObH9Wz1K9Z3klvxALF5x3Fv7GMvoc-10YMUNQhC25btNrqkbQwGw=w2400"
  },
  {
    comment: `I have been able to actually consume more data on design than I normally would & it is a constant reminder to keep improving. We are a carefully curated bunch & that shows how intentional we are about everything. ♥️`,
    name: "Mudia Imasuen",
    role: "Product designer",
    image: "https://lh4.googleusercontent.com/IsqAX3U21fCKPGyNmb9OvxbSzwV6UjM6EpMMaBnl8xhY01ivGyoiyXldoCWtnUdlSpU=w2400"
  },
  {
    comment: `The Asa Sessions have been of most value to me
      `,
    name: "Liz Agboola",
    role: "Brand designer",
    image: "https://lh6.googleusercontent.com/RijpzpkWNXZSbeZBcfGAxPTZRFAwYapWJ9X2cXZn1Yhcs6UTQgL57eOQlkKQDrn4Gwk=w2400"
  },
  {
    comment: `A family, tons of resources, indirect mentorship, exposure. Many more.
      `,
    name: "Eronmonsele Okojie",
    role: "Product designer",
    image: "https://lh3.googleusercontent.com/wTwaJxC2FBylgthn5UEyfGQvmGewdiYMF4Pzdk8bXxrTQ-GLwBGxekpY5QWNzmAhLSc=w2400"
  },
  {
    comment: `The wealth of talent has been tremendous and the collaborative effort.
      Seeing folks like myself do excellent work inspires me
      `,
    name: "Charles Njoku",
    role: "Product designer",
    image: "https://lh3.googleusercontent.com/rScqMFTy0bQbdH6f2WyYCUPqUIe-ZxxplcPRKkWinnyCzAEBEjfXdvNU0l_1Vh0Zhk0=w2400"
  },
  {
    comment: `I love the community. They’ve helped a lot of people and me personally. It feels more like a community of friends than a community of designers.
      `,
    name: "Sonia Nelson",
    role: "Product designer",
    image: "https://lh5.googleusercontent.com/u9ydDyfzac8DVNmO9jMxAm-3TBwPlt1F54QreKo7L4E5CiZWZGV8v8MwmqDLuXdvxpc=w2400"
  },
  {
    comment: `I've learnt more on my field and also better understanding of other aspects of design.
      `,
    name: "Precious Ogar",
    role: "Product designer",
    image: "https://lh6.googleusercontent.com/iEitJ6udbLK_GzfTa4dNyVYFk9W4C9CHXydPIc4h0iENup-dxHYvw-XJ94fN__fZgWk=w2400"
  },
  {
    comment: `Daily distribution of knowledge and best practices. 
      `,
    name: "Marvin Ogah",
    role: "Product designer",
    image: "https://lh5.googleusercontent.com/7DFQjPX5-j_STvxjcZl3dZ11lnD2PaXsrDyobcUL2pxEe1UdpRXioHuetwpkJyu1vG4=w2400"
  },
  {
    comment: `Asa Coterie has helped me understand UI/UX better and  helped me build a reading habit.`,
    name: "Queennette  Kachi",
    role: "Product designer",
    image: "https://lh4.googleusercontent.com/EE5YUXYfU-RV6Af1GypEfV2T6U64wwtZzbfphp6twQ0tPyC8qyZC-qh5HfDf2uoGRJ0=w2400"
  },
  {
    comment: `It has helped me in my freelance life, especially relating with client, making them understand clearly the kind of value they are getting from me. Also strategic steps to take for any design project also.`,
    name: "Dideoluwa Ololade",
    role: "Visual designer",
    image: "https://lh6.googleusercontent.com/fD_Qo0v4vswrZQYI9x1MaI5pdurc_56zT5ITw_N_9-_PukjRf9Q_DGvyBzkn6Uzp6tE=w2400"
  },
  {
    comment: `Multidisciplinary learning 
      `,
    name: "Ibrahim Salami",
    role: "Product designer",
    image: "https://lh4.googleusercontent.com/UZdcaasDoKvAeQt0-zvExtlOmp8zFBGTIK8pWNXeHT1kzfn_tjbQLKkNVum-ssDCb8M=w2400"
  }
];
