import styled, { css, keyframes } from "styled-components";
import { white, black, grey, beige } from "theme/colors";
import * as variables from "theme/variables";
import { responsive } from "theme";

const ContainerStyles = css`
  max-width: 108rem;
  @media only screen and (max-width: 1080px) {
    max-width: 100%;
    padding: 0 3rem;
  }
`;

const headingStyles = css`
  .heading {
    font-family: "Migra";
    font-weight: normal;
    font-size: 4.4rem;
    line-height: 110%;
    color: ${black[100]};
    max-width: 43.2rem;
    margin-top: 6.4rem;
    margin-bottom: 1.2rem;
    max-height: ${variables.sidenavWidth};
    ${responsive.mobile`
      max-height: ${variables.sidenavWidthSM};
    `}
  }
  .sub-text {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: ${black[100]};
    max-width: 31.2rem;
    margin-bottom: 4.8rem;
  }
`;

export const ContactsStyles = styled.div`
  ${ContainerStyles}
  margin: 0 auto;
  margin-bottom: ${variables.sidenavWidth};
  ${responsive.mobile`
    margin-bottom: ${variables.sidenavWidthSM};
  `}
  display: flex;
  ${responsive.tabMid`
  padding: 5rem 2rem;
    flex-direction: column;
`}
  .first-column {
    display: flex;
    flex-direction: column;
    ${headingStyles}

    .btn {
      width: fit-content;
      margin-top: auto;
    }
  }
  .second-column {
    flex: 1;
    padding: 6rem 8rem;
    border-right: ${variables.borderStyle};
    border-left: ${variables.borderStyle};
    ${responsive.tabMid`
        padding: 6rem 0;
        border: unset;
    `}
  }
  form {
    flex: 1;
  }
  .form {
    &-field {
      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }
      &:last-child {
        margin-bottom: 4rem;
      }
    }
    &__label {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.02em;
      display: block;
      color: ${black[100]};
    }
    &__input {
      height: 6.4rem;
      border: ${variables.borderStyle};
      border-width: 1px;
      box-sizing: border-box;
      width: 100%;
      padding: 2rem 2.4rem;
      font-size: 2rem;
      color: ${black[100]};
      background: transparent;
      &::placeholder {
        font-size: 2rem;
        line-height: 2.4rem;
        letter-spacing: 0.02em;
        color: ${beige[200]};
      }
    }
  }
  .btn--secondary {
    width: 100%;
    margin-bottom: 1.6rem;
  }

  .nb {
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: 0.01em;
  }
`;
