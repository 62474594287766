export const white = {
  100: "#FFFFFF",
  200: "#FAFAF9"
};

export const black = {
  100: "#1C1616"
};

export const blue = {
  100: "#2B57F2",
  200: "#6CC8E1"
};

export const red = {
  50: "#EA86AA",
  100: "#E42C48"
};

export const grey = {
  100: "#8A7575",
  200: "#C2C6D6",
  300: "#8C7C73"
};

export const beige = {
  100: "#F0E9E5",
  200: "#c3bbbb"
};
export const brown = {
  100: "#BB4F07"
};

export const orange = {
  100: "#FE982A"
};

export const green = {
  100: "#005236",
  200: "#B8EA86"
};

export const purple = {
  100: "#A59FEF"
};

export const yellow = {
  100: "#F0EB75"
};
