import { css } from "styled-components";

export const responsive = {
  mobile: (...args) => css`
    @media (max-width: 37.5em) {
      //600px
      ${css(...args)}
    }
  `,
  tabSM: (...args) => css`
    @media (max-width: 46.25em) {
      //740px
      ${css(...args)}
    }
  `,
  tabPortrait: (...args) => css`
    @media (max-width: 62.19em) {
      //995px
      ${css(...args)}
    }
  `,
  tabMid: (...args) => css`
    @media (max-width: 69em) {
      //1104px
      ${css(...args)}
    }
  `,
  tabLandscape: (...args) => css`
    @media (max-width: 75em) {
      //1200px
      ${css(...args)}
    }
  `
};

// export const responsive = {
//   mobile: {
//     standard: (...args) => css`
//         @media (max-width: 37.5em) {
//         //600px
//         ${css(...args)}
//       }
//     `
//   }
// };

// @mixin respond($breakpoint) {
//     @if $breakpoint == phone {
//       @media (max-width: 37.5em) {
//         //600px
//         @content;
//       }
//     }
//     @if $breakpoint == tab-sm {
//       @media (max-width: 46.25em) {
//         //740px
//         @content;
//       }
//     }
//     @if $breakpoint == tab-port {
//       @media (max-width: 62.19em) {
//         //995px
//         @content;
//       }
//     }
//     @if $breakpoint == tab-mid {
//       @media (max-width: 69em) {
//         //1104px
//         @content;
//       }
//     }
//     @if $breakpoint == tab-land {
//       @media (max-width: 75em) {
//         //1200px
//         @content;
//       }
//     }
//     @if $breakpoint == dash-change {
//       @media (max-width: 80em) {
//         //1280px
//         @content;
//       }
//     }
//     @if $breakpoint == big-desktop {
//       @media (min-width: 112.5em) {
//         //1800px
//         @content;
//       }
//     }
//     @if $breakpoint == laptopsAndAbove {
//       @media (min-width: 80em) {
//         //1280px
//         @content;
//       }
//     }
