import React from "react";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/Input/TextInput";
import { ContactsStyles } from "./contact.style";

export default function Contact(props) {
  function subscribeToMailingList(email, firstname = "") {
    const formId = process.env.REACT_APP_FORM_ID;
    const apiRequest = `https://api.convertkit.com/v3/forms/${formId}/subscribe`;
    return axios.post(apiRequest, {
      api_key: process.env.REACT_APP_API_KEY,
      email: email,
      first_name: firstname
    });
  }
  return (
    <ContactsStyles>
      <div className="first-column">
        <h5 className="heading">Stay with us</h5>
        <p className="sub-text">
          A multi-disciplinary design newsletter for designers, by designers. A guide on how to live and work as a
          modern designer.
        </p>
      </div>
      <Formik
        initialValues={{
          email: "",
          first_name: ""
        }}
        validationSchema={Yup.object({
          email: Yup.string().required().email("Enter a valid email"),
          first_name: Yup.string().required()
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          subscribeToMailingList(values.email, values.first_name)
            .then(res => {
              setSubmitting(false);
              resetForm();
            })
            .catch(err => {
              setSubmitting(false);
            });
        }}
      >
        {({ setSubmitting, isSubmitting, resetForm }) => (
          <Form>
            <div className="second-column">
              <TextInput
                id="first_name"
                name="first_name"
                type="text"
                className="form__input"
                label="First Name"
                placeholder="Sobaru Mark"
              />

              <TextInput
                id="email"
                name="email"
                type="email"
                className="form__input"
                label="Email address"
                placeholder="E.g; your@email.com"
              />

              <button type="submit" disabled={isSubmitting} className="btn btn--secondary">
                {isSubmitting ? "Submitting" : "subscribe to our newsletter"}
              </button>
              <p className="nb">You can unsubscribe at any time, no hard feelings.</p>
            </div>
          </Form>
        )}
      </Formik>
    </ContactsStyles>
  );
}
