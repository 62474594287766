import styled, { css, keyframes } from "styled-components";
import { black, purple, green, yellow, red, beige, white, grey, blue } from "theme/colors";
import * as variables from "theme/variables";
import { responsive } from "theme";

const scrolling = keyframes`
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(-1417px);
}
`;
export const Scrolling = styled.section`
  padding: 3rem 0;
  background: ${beige[100]};
  .text {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    font-family: "Shapiro";
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: ${black[100]};
    & > * {
      display: inline-block;
      animation: ${scrolling} 20s infinite linear both;
    }
    .chunk {
      & > * {
        margin-right: 1.2rem;
      }
    }
  }
`;
