import styled, { css } from "styled-components";
import { white, blue, black, grey, beige } from "../../theme/colors";
import * as variables from "../../theme/variables";
import { responsive } from "theme";
import { NavLink } from "react-router-dom";
export const HeaderStyle = styled.header`
  background: ${white[200]};
  display: flex;
  align-items: stretch;
  padding: unset;
  border-bottom: ${variables.borderStyle};
  height: ${variables.sidenavWidth};
  position: fixed;
  margin-left: ${variables.sidenavWidth};
  width: calc(100% - ${variables.sidenavWidth});
  left: 0;
  top: 0;
  z-index: 20;
  ${responsive.mobile`
    height: ${variables.sidenavWidthSM};
    margin-left: ${variables.sidenavWidthSM};
    width: calc(100% - ${variables.sidenavWidthSM});
  `}
`;

export const SecondColumnStyle = styled.div`
  margin-left: auto;
  display: flex;
  & > * {
    border-left: ${variables.borderStyle};
  }
`;
const headerLinkStyles = css`
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  min-width: 18rem;
  padding: 3.4rem 2.2rem;
  display: inline-block;
  color: ${black[100]};
  text-decoration: none;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
  &.bg-blue {
    background-color: ${blue[100]};
    color: ${white[100]};
  }
  &.isActive {
    background: ${beige[100]};
  }
  ${responsive.mobile`
      display: none;
      min-width: unset;
      width: fit-content;
      padding: 2.2rem;
      &.show-sm{
        display: inline-block
      }
    `}
  &.menu {
    display: none;
    ${responsive.mobile`
      display: flex;
      align-items: center;
      & :not(:last-child){
        margin-right: 1rem;
      }
    `}
  }
`;
export const HeaderLinkStyle = styled(NavLink)`
  ${headerLinkStyles};
`;
export const HeaderLinkStyle2 = styled.a`
  ${headerLinkStyles};
`;
export const SidebarStyles = styled.div`
  position: fixed;
  z-index: 10;
  width: calc(100% - ${variables.sidenavWidth});
  height: calc(100% - 8.6rem);
  background: ${white[100]};
  right: 0;
  top: 8.6rem;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  ${responsive.mobile`
    width: calc(100% - ${variables.sidenavWidthSM});
  `}
  &.open {
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
  }
  .list {
    list-style: none;
    flex: 1;
    padding-left: unset;
    &__item {
      border-bottom: ${variables.borderStyle};
    }
    &__link {
      display: flex;
      align-items: center;
      padding: 2.6rem 1.6rem 1rem 3rem;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: -0.01em;
      text-transform: capitalize;
      text-decoration: none;
      color: ${black[100]};

      & > :not(:last-child) {
        margin-right: auto;
      }
    }
  }
`;
export const FooterStyles = styled.footer`
  display: flex;
  flex-direction: column;
  .copy {
    display: block;
    padding: 1rem 1.5rem;
  }
  .socials {
    display: flex;
    margin-right: 5.2rem;
    ${responsive.tabMid`
            padding: 2.6rem 1.5rem;
            margin-right: unset;
            border-bottom: ${variables.borderStyle};
        `}
    & > :not(:last-child) {
      margin-right: 5.2rem;
    }
  }
  .hello {
    background: ${blue[100]};
    text-decoration: none;
    height: 100%;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 2.6rem 1.5rem;
    display: flex;
    align-items: center;
    color: ${grey[200]};
    width: 100%;
    span:not(:first-child) {
      color: ${white[100]};
    }
  }
`;
