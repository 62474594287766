import React from "react";
import { ReactComponent as Twitter } from "assets/icons/svg/twitter.svg";
import { ReactComponent as LinkedIn } from "assets/icons/svg/linkedin.svg";
import { ReactComponent as Instagram } from "assets/icons/svg/instagram.svg";
import { ReactComponent as Youtube } from "assets/icons/svg/youtube.svg";
import { SocialsStyle, FooterStyles } from "./footer.styles";

const Footer = React.forwardRef((props, ref) => {
  const { firstFooterRef, secondFooterRef } = ref;
  function Footer() {
    return (
      <FooterStyles>
        <SocialsStyle className="first-container show" ref={firstFooterRef}>
          <div className="div">
            <a href="https://twitter.com/AsaCoterie" rel="noreferrer" target="_blank" className="social-link">
              <Twitter />
            </a>
            <a
              href="https://www.linkedin.com/company/asadsgn/"
              rel="noreferrer"
              target="_blank"
              className="social-link"
            >
              <LinkedIn />
            </a>
            <a href="https://instagram.com/asacoterie" rel="noreferrer" target="_blank" className="social-link">
              <Instagram />
            </a>
            <a
              href="https://www.youtube.com/channel/UCHJ5YiYKzsrEZg9Zlmc__MA"
              rel="noreferrer"
              target="_blank"
              className="social-link"
            >
              <Youtube />
            </a>
          </div>
        </SocialsStyle>
        <div className="second-container" ref={secondFooterRef}>
          <div className="first-column">
            <p className="copy">© Àṣà Design Haus Ltd, 2021</p>
          </div>
          <div className="second-column">
            <div className="socials">
              <p className="text">Follow us:</p>
              <a href="https://twitter.com/AsaCoterie" rel="noreferrer" target="_blank" className="social-link">
                <Twitter />
              </a>
              <a
                href="https://www.linkedin.com/company/asadsgn/"
                rel="noreferrer"
                target="_blank"
                className="social-link"
              >
                <LinkedIn />
              </a>
              <a href="https://instagram.com/asacoterie" rel="noreferrer" target="_blank" className="social-link">
                <Instagram />
              </a>
              <a
                href="https://www.youtube.com/channel/UCHJ5YiYKzsrEZg9Zlmc__MA"
                rel="noreferrer"
                target="_blank"
                className="social-link"
              >
                <Youtube />
              </a>
            </div>
            <p className="copy copy--sm">© Àṣà Design Haus Ltd, 2021</p>
            <a href="mailto:hello@asadsgn.com?subject=Hello%20Asa" rel="noreferrer" target={"_blank"} className="hello">
              <span>say:</span>
              <span>hello@asadsgn.com</span>
            </a>
          </div>
        </div>
      </FooterStyles>
    );
  }

  return <Footer />;
});
export default Footer;
